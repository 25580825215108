import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {Col, Row} from 'react-bootstrap';
import FormField from '@/shared/components/form/FormField';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import PackageSelection1 from "./Step1-package1"
import PackageSelection2 from "./Step1-package2"
import TokenSelection from "./Step1-token"
import renderDropZoneField from '@/shared/components/form/dropzones/DropZone';

const VerticalForm = ({ onSubmit }) => {
  const { t } = useTranslation('common');
  return (
    <Col md={12} lg={12} xl={6}>

      <Form onSubmit={onSubmit} validate={validate}>
        {({
            handleSubmit,
            form: { reset },
          }) => (
      <Card>
        <Row>
        <Col md={12}>
          <h1 className="page-title">Welcome to Your Token Launchpad Setup</h1>
          <h2 className="page-subhead">Begin Your Journey with Us</h2>
          <h3 className="page-subhead subhead">
            Welcome to the most straightforward way to launch your token! Whether you’re looking to create a new
            token or launch a presale for an existing one, you’re in the right place. Our step-by-step process
            ensures clarity and ease from start to finish.
          </h3>
        </Col>
      </Row>

        <FormGroup>
          <FormContainer>
            <PackageSelection1/>
            <PackageSelection2/>
          </FormContainer>
        </FormGroup>

        <Row>
          <Col md={12}>
            <h1 className="page-title">🪐✨ Step 1: Token Selection</h1>
            <h3 className="page-subhead subhead">
              You can start with your own token contract or select a standard Koincity token contract.
            </h3>
          </Col>
        </Row>
        <CardBody>
          <FormContainer onSubmit={handleSubmit}>
            <FormGroup>
              <FormContainer>
                <TokenSelection/>
              </FormContainer>
            </FormGroup>

            <FormGroup>
              <FormGroupLabel>Token</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Name</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="EX: Bitcoin"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Symbol</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="EX: BTC"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Supply</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="EX: 21000000"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Token Description</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="description"
                        component="textarea"
                        type="text"
                    />
                  </FormGroupField>
                </FormGroup>

                <FormGroup>
                </FormGroup>
                <FormGroup>

                  <CardTitleWrap>
                    <CardTitle>Upload Your Token Logo</CardTitle>
                    <CardSubhead>
                      Your token’s logo serves as the visual identity of your project and will be prominently displayed
                      in all related materials and exchanges. Ensure it aligns with your brand and communicates the
                      essence of your token effectively.
                    </CardSubhead>
                  </CardTitleWrap>

                  <FormGroupField>
                    <Field
                        name="files"
                        component={renderDropZoneField}
                        customHeight={false}
                    />
                  </FormGroupField>
                </FormGroup>


                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Step 2</Button>
                </FormButtonToolbar>
              </FormContainer>
        </CardBody>
      </Card>
        )}
      </Form>
    </Col>
  );
};

VerticalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default VerticalForm;
