import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '@/shared/components/Panel';

const mapContainerStyle = {
  height: '360px',
};

const center = {
  lat: 41.876,
  lng: -87.624,
};

const TodayRunningMap = () => {
  const { t } = useTranslation('common');

  return (
    <Panel xs={12} lg={12} xl={9} md={12} title={t('fitness_dashboard.today_running_map')}>
    </Panel>
  );
};

export default TodayRunningMap;
