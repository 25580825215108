import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';

const chart_label = "Fundings volume in KOIN: "

const RandomAnimatedBars = ({ sub_contract, raised }) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: chart_label,
        backgroundColor: '#4ce1b6',
        borderColor: '#4ce1b6',
        borderWidth: 1,
        hoverBackgroundColor: '#4ce1b6',
        hoverBorderColor: '#4ce1b6',
        data: [],
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: true,
      text: chart_label + raised,
    },
    animation: {
      duration: 2000,
      easing: 'easeInOutQuart',
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          const value = tooltipItem.yLabel;
          return `${label}: $${value}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            /*color: 'rgb(204, 204, 204)',
            borderDash: [3, 3],*/
          },
          ticks: {
         /*   fontColor: 'rgb(204, 204, 204)',*/
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            /*color: 'rgb(204, 204, 204)',*/
           /* borderDash: [3, 3],*/
          },
          ticks: {
            /*fontColor: 'rgb(204, 204, 204)',*/
            callback:(value, index, ticks) => {
              return '$' + value; // gets 0 index
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/kct_launchpad_volume`, {
          params: { id: sub_contract },
        });

        // Process the response data here
        const apiData = response.data;

        if (isMounted && apiData.success) {
          const dataRows = apiData.rows; // Extract the array from the response

          // Map over the dataRows array to extract labels and chartData
          const labels = dataRows.map((item) => (item.is_current_interval ? 'now' : item.interval_start));
          const chartData = dataRows.map((item) => Number(Number(item.total_usd_amount).toFixed(0)));

          setData({
            labels,
            datasets: [
              {
                label: chart_label,
                backgroundColor: '#4ce1b6',
                borderColor: '#4ce1b6',
                borderWidth: 1,
                hoverBackgroundColor: '#4ce1b6',
                hoverBorderColor: '#4ce1b6',
                data: chartData,
              },
            ],
          });
        } else {
          console.error('API response was not successful');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    // Cleanup function
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [sub_contract, raised]); // Dependency array includes sub_contract

  return (
      <Col md={12} lg={12} xl={12}>
        <Card style={{padding: '0'}}>
          <CardBody>
            {false &&
            <CardTitleWrap>
              <CardTitle>$ Fundings Volume</CardTitle>
            </CardTitleWrap>
            }
            <div>
              <Bar height={288} key={sub_contract} data={data} options={options}/>
            </div>
          </CardBody>
        </Card>
      </Col>
  );
};

export default RandomAnimatedBars;
