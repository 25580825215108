import axios from 'axios';
import store from '../../../../redux/store/store';
import { kapInfoMainnetContract, mainnetProviderUrl } from '../../../constants/koincity-constants';
import {Contract, Provider, Signer} from "koilib";
import kapinfoAbi from "../ABI/kapinfo-abi.json";
import nftAbi from "../ABI/nft-abi.json";
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';

function normalizeIpfsUris(uri) {
    let result = uri;
    if (uri.startsWith("ipfs://")) {
        const path = uri.indexOf("/", 7);
        if (path > -1) {
            result =
                "https://" +
                uri.substring(7, path) +
                ".ipfs.nftstorage.link" +
                uri.substring(path);
        } else {
            result = "https://" + uri.substring(7) + ".ipfs.nftstorage.link";
        }
    }
    return result;
}

function defaultAvatar(address) {
    const avatar = createAvatar(identicon, {
        size: 150,
        seed: address
    }).toDataUriSync();
    localStorage.setItem('avatar', avatar);
}

async function setAvatar(avatar_contract_id, avatar_token_id, address){
    let avatar = null
    let avatarDefault = false
    const provider = new Provider([mainnetProviderUrl]);
    const signer = Signer.fromSeed("koincity");
    signer.provider = provider;

    const Abi = {
        koilib_types: nftAbi.types,
        ...nftAbi
    }

    const contract = new Contract({
        id: avatar_contract_id,
        abi: Abi,
        provider,
        signer,
    });

    const nft = contract.functions;
    const result = (await nft.uri({
    })).result.value;
    let uri = normalizeIpfsUris(result)
    try {
        const metadata = await fetch(`${uri}/${avatar_token_id}`);
        const {image} = await metadata.json();
        avatar = normalizeIpfsUris(image);
        await localStorage.setItem('avatar', avatar);
    } catch(e){
        avatarDefault = true;
        defaultAvatar(address);
    }

    console.log(avatar, avatarDefault)
}

async function checkUserKapImage(address) {
    const provider = new Provider([mainnetProviderUrl]);
    const kapContract = kapInfoMainnetContract;

    const signer = Signer.fromSeed("koincity");
    signer.provider = provider;

    const Abi = {
        koilib_types: kapinfoAbi.types,
        ...kapinfoAbi
    }

    const contract = new Contract({
        id: kapContract,
        abi: Abi,
        provider,
        signer,
    });


    const data = contract.functions;

    const result = (await data.get_profile({
        address: address
    })).result;

    const {avatar_contract_id, avatar_token_id} = result

    setAvatar(avatar_contract_id, avatar_token_id, address)

}
async function checkUserNicknameImage(avatar) {
    await localStorage.setItem('avatar', avatar);
}

// eslint-disable-next-line import/prefer-default-export
export async function getUserData(address){

    if(address.length > 0) {
        try {

            const response = await axios.get(process.env.REACT_APP_DOMAIN + "/api/kct_user_id", {
                params: {
                    "userAddress": address
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.data.success) {
                const data = response.data.data
                console.log(data)
                await localStorage.setItem('connectedId', data.id);
                await localStorage.setItem('signedMessage', data.signed_message);
                await localStorage.setItem('kapDomainUsername', data.kap_domain_username);
                await localStorage.setItem('kapDomain', data.kap_domain);
                await localStorage.setItem('nicknameUsername', data.nickname_username);
                await localStorage.setItem('nickname', data.nickname);
                await localStorage.setItem('usernameValue', data.name);
                store.dispatch({
                    type: "USER",
                    payload: { username: data.name }
                });

                store.dispatch({
                    type: "ID",
                    payload: { id: data.id }
                });

                if (data.kap_domain_username == "true"){
                    checkUserKapImage(address);
                } else if (data.nickname_username == "true" && !!data.image){
                    checkUserNicknameImage(data.image);
                } else{
                    defaultAvatar(address)
                }



            } else {
                localStorage.removeItem('connectedId');
                localStorage.removeItem('signedMessage');
                localStorage.removeItem('kapDomainUsername');
                localStorage.removeItem('kapDomain');
                localStorage.removeItem('nicknameUsername');
                localStorage.removeItem('nickname');
                localStorage.removeItem('usernameValue');
                defaultAvatar(address)

            }
        } catch (e) {
            console.log(e)
        }
    }

}
