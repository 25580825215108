import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {Col, Row} from 'react-bootstrap';
import FormField from '@/shared/components/form/FormField';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import LaunchType from "./Step2-launch-type"
import LaunchContribution from "./Step2-launch-contribution"
import DatePicker from "./Step3-datepicker"
import {FormGroupIcon} from "../../../../shared/components/form/FormElements";

const VerticalForm = ({ onSubmit }) => {
  const { t } = useTranslation('common');
  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
      <Row>
        <Col md={12}>
          <h1 className="page-title">💧 Step 3: Liquidity and Pool Configuration</h1>
          <h3 className="page-subhead subhead">
            Ensure Your Token’s Long-Term Viability
          </h3>
        </Col>
      </Row>
        <CardBody>
        <Form onSubmit={onSubmit} validate={validate}>
            {({
              handleSubmit,
              form: { reset },
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormGroup>
                  <DatePicker/>
                </FormGroup>
                <FormGroup>
                  <CardTitle>Sale and Listing Prices</CardTitle>
                  <CardSubhead>
                    Set the token amounts for investors during the funding period and the launch-price for traders.
                  </CardSubhead>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel><b>Sale price</b>: tokens amount per Koin during sale</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="softcap"
                        component={FormField}
                        type="number"
                        placeholder="EX: 100,000 Tokens for each funded KOIN"
                    />
                    <FormGroupIcon>
                      <p>
                        Tokens
                      </p>
                    </FormGroupIcon>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel><b>Listing price</b>: tokens amount per Koin at launch</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="softcap"
                        component={FormField}
                        type="number"
                        placeholder="EX: 80,000 Tokens are worth one Koin at DEX launch"
                    />
                    <FormGroupIcon>
                      <p>
                        Tokens
                      </p>
                    </FormGroupIcon>
                  </FormGroupField>
                </FormGroup>


                <FormGroup style={{marginTop: '25px'}}>
                    <CardTitle>Fundings for creating automatically a DEX trading pair</CardTitle>
                    <CardSubhead>
                      Define the funded KOIN amount in percent to be used to create automatically the KOIN/TOKEN trading
                      pair. For entries between 50% and 99% a payout in KOIN will be automatically processed into your wallet.
                    </CardSubhead>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Create trading pair with % funded KOIN</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="maxContribution"
                        component={FormField}
                        type="number"
                        placeholder="EX: 50-100"
                    />
                    <FormGroupIcon>
                      <p>
                        %
                      </p>
                    </FormGroupIcon>
                  </FormGroupField>
                </FormGroup>


                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Step 4</Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};
http://localhost:3000/easydev/booking_dashboard
VerticalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default VerticalForm;
