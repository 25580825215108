import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import showResults from '@/utils/showResults';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Review from './Review';

const createLaunchpadForm = () => {
  const { t } = useTranslation('common');

  return (
    <Container>
        <Row>
            <Step1 onSubmit={showResults} />
        </Row>
        <Row>
            <Step2 onSubmit={showResults} />
        </Row>
        <Row>
            <Step3 onSubmit={showResults} />
        </Row>
        <Row>
            <Step4 onSubmit={showResults} />
        </Row>
        <Row>
            <Step5 onSubmit={showResults} />
        </Row>
        <Row>
            <Review onSubmit={showResults} />
        </Row>
    </Container>
  );
};

export default createLaunchpadForm;
