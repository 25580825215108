import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Summary from './components/Summary';
import Funding from './components/Funding';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { getPresaleContractFunctions, getPresaleStatus, getPresaleStatusIcon } from './components/Functions';

const ProjectSummary = () => {
    const rtl = useSelector((state) => state.rtl);
    const { id } = useParams();

    const [launchpadData, setLaunchpadData] = useState([]);
    const [evaluationData, setEvaluationData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    async function calculateRatings(data) {
        const {
            sub_contract,
            startdate,
            enddate,
            liquiditylockdate,
            liquiditypoolpercentage,
            tokenperkoinlisting,
            tokenperkoinpresale
        } = data
        const pContractFunctions = await getPresaleContractFunctions(true)

        const presale =  ((await pContractFunctions.getPresales({
            start: sub_contract,
            limit: 1,
            direction: 1,
        })).result)?.value[0];

        function getDurationPoints(duration) {
            const days = duration / 60 / 60 / 24
            if(days <= 2) return 5;
            else if(days <= 7) return 4;
            else if(days <= 14) return 3;
            else if(days <= 30) return 2;
            else if(days <= 90) return 1;
            else return 0;
        }
        function getLpLockPoints(lpLock) {
            const days = lpLock / 60 / 60 / 24
            if(days > 0 && days <= 45) return 1;
            else if(days > 0 && days <= 180) return 2;
            else if(days > 0 && days <= 360) return 3;
            else if(days > 0 && days <= 720) return 4;
            else if(days > 0 && days > 720) return 5;
            else return 0;
        }
        function getSaleListingRatePoints(rate) {
            if(rate <= 5) return 5;
            else if(rate <= 15) return 4;
            else if(rate <= 30) return 3;
            else if(rate <= 50) return 2;
            else if(rate <= 100) return 1;
            else return 0;
        }
        function getLpPercentagePoints(lp_percentage) {
            if(lp_percentage >= 98) return 5;
            else if(lp_percentage >= 90) return 4;
            else if(lp_percentage >= 75) return 3;
            else if(lp_percentage >= 60) return 2;
            else if(lp_percentage >= 52) return 1;
            else return 0;
        }

        const duration = Math.floor((enddate - startdate) / 1000);
        const lp_lock = Math.floor((liquiditylockdate - enddate) / 1000);
        const sale_listing_rate = ((tokenperkoinpresale - tokenperkoinlisting) / tokenperkoinlisting) * 100
        const lp_percentage = Number(liquiditypoolpercentage)


        const _duration = getDurationPoints(duration);
        const _lp_lock = (!!presale.burnLiquidity) ? 5 : getLpLockPoints(lp_lock)
        const _sale_listing_rate = getSaleListingRatePoints(sale_listing_rate)
        const _lp_percentage = getLpPercentagePoints(lp_percentage)

        const firstRating = _duration + _lp_lock + _sale_listing_rate + _lp_percentage

        const result = {
            duration: _duration,
            lp_lock: _lp_lock,
            sale_listing_rate: _sale_listing_rate,
            lp_percentage: _lp_percentage,
            lp_burn: (!!presale.burnLiquidity) ? true : false,
            audit: (!!presale.trust && !!presale.trust.audit) ? true : false,
            first_rating: firstRating,
        }
        setEvaluationData(result)
        console.log("result", result)
    }

    useEffect(() => {
        const fetchLaunchpadData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/kct_launchpad`, {
                    params: { id },
                });
                setLaunchpadData(response.data.rows[0]);
                calculateRatings(response.data.rows[0])
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchLaunchpadData();
    }, [id]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading data: {error.message}</p>;
    }

    return (
        <Container>
            {/* Helmet for managing meta tags */}
            <Helmet>
                <title>{launchpadData.symbol + " | " + getPresaleStatus(launchpadData.status) + " " + getPresaleStatusIcon(launchpadData.status)}</title>
                <meta name="description" content={launchpadData.description} />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@TheRealKoincity" />
                <meta name="twitter:title" content={launchpadData.symbol + " | " + getPresaleStatus(launchpadData.status) + " " + getPresaleStatusIcon(launchpadData.status)} />
                <meta name="twitter:description" content={launchpadData.description} />
                <meta name="twitter:image" content={launchpadData.logo} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={launchpadData.symbol + " | " + getPresaleStatus(launchpadData.status) + " " + getPresaleStatusIcon(launchpadData.status)} />
                <meta property="og:description" content={launchpadData.description} />
                <meta property="og:image" content={launchpadData.logo} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>

            <Row>
                <Col md={12}>
                    <h3 className="page-title">
                        <FavImage alt="Favorite Launchpad" src="/img/launchpad/stars_outline.svg" />
                        {launchpadData.name}
                    </h3>
                    <h3 className="page-subhead subhead">{launchpadData.symbol}</h3>
                </Col>
            </Row>
            <Row>
                <Summary dir={rtl.direction} launchpadData={launchpadData} evaluationData={evaluationData}/>
                <Funding launchpadData={launchpadData} />
            </Row>
        </Container>
    );
};

export default ProjectSummary;

const FavImage = styled.img`
    width: 16px;
    margin-top: -3px;
    margin-right: 5px;
`;
