import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {Col, Row} from 'react-bootstrap';
import FormField from '@/shared/components/form/FormField';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import LaunchType from "./Step2-launch-type"
import WhoParticipate from "./Step4-who-participate"
import UnsoldTokens from "./Step4-unsold-tokens"
import LaunchContribution from "./Step2-launch-contribution"
import DatePicker from "./Step3-datepicker"
import {FormGroupIcon} from "../../../../shared/components/form/FormElements";

const VerticalForm = ({ onSubmit }) => {
  const { t } = useTranslation('common');
  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
      <Row>
        <Col md={12}>
          <h1 className="page-title">📢 Step 5: Marketing and Outreach</h1>
          <h3 className="page-subhead subhead">
            Develop your strategy to connect with potential contributors. This step guides you in setting up your
            website, crafting impactful messages, and linking your social media channels to maximize visibility and
            engagement.
          </h3>
        </Col>
      </Row>
        <CardBody>
          <Form onSubmit={onSubmit} validate={validate}>
            {({
              handleSubmit,
              form: { reset },
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <CardTitleWrap>
                  <CardTitle>Connect with Your Audience</CardTitle>
                  <CardSubhead>
                    Having a website and 𝕏 account can enhance your visibility. If you aim for higher fundings
                    then commonly paid influencers marketing through social networks (e.g. Telegram and 𝕏) can be
                    used to find new investors; don't hesitate to ask the Koincity community for guidance and advice.
                  </CardSubhead>
                </CardTitleWrap>
                <FormGroup>
                  <FormGroupLabel>Website</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Whitepaper</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Telegram</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Discord</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Medium</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Bitcointalk</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Reddit</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>𝕏</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Facebook</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="username"
                        component={FormField}
                        type="text"
                        placeholder="Enter your token address"
                        isAboveError
                    />
                  </FormGroupField>
                </FormGroup>

                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Review</Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};
http://localhost:3000/easydev/booking_dashboard
VerticalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default VerticalForm;
