import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import ProductItems2 from './ProductItems2';
import axios from 'axios';

const getLaunchpads = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_DOMAIN + "/api/kct_launchpads", {
            params: {},
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.data.success) {
            return response.data.rows;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching launchpads:", error);
        return [];
    }
}

const CatalogItems = () => {
    const [launchpads, setLaunchpads] = useState([]);

    // Fetch the launchpads when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            const data = await getLaunchpads();
            setLaunchpads(data);
        };

        fetchData();
    }, []); // Empty dependency array ensures this effect runs only once on mount

    return (
        <Col md={12} lg={12}>
            {/* Pass the fetched launchpads to ProductItems2 */}
            <ProductItems2 items={launchpads} />
        </Col>
    );
};

export default CatalogItems;
