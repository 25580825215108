
import {Contract, Provider, Signer} from "koilib";
import presalemapAbi from "../../../../../shared/components/wallet/ABI/presalemap-abi.json"
import tokenAbi from "../../../../../shared/components/wallet/ABI/token-abi.json"
import tokenlockAbi from "../../../../../shared/components/wallet/ABI/tokenlockmap-abi.json"
import {
    harbingerProviderUrl,
    mainnetPresalemapContract,
    mainnetProviderUrl,
    tokenlockmapContract
} from '../../../../../shared/constants/koincity-constants';

export const getPresaleContractFunctions = async (mainnet = false, signer = null) => {
    let provider = null;
    let presalemapContract = mainnetPresalemapContract;

    if (mainnet) {
        provider = new Provider([mainnetProviderUrl]);
    } else {
        provider = await new Provider([harbingerProviderUrl]);
    }
    if(signer == null) {
        signer = Signer.fromSeed("KCMB");
    }

    signer.provider = provider;

    const Abi = {
        koilib_types: presalemapAbi.types,
        ...presalemapAbi
    };

    const presaleContract = new Contract({
        id: presalemapContract,
        abi: Abi,
        provider,
        signer,
    });

    const result = presaleContract.functions;

    return result;
};

export const getTokenlockContractFunctions = async (mainnet = false, signer = null) => {
    let provider = null;

    if (mainnet) {
        provider = new Provider([mainnetProviderUrl]);
    } else {
        provider = await new Provider([harbingerProviderUrl]);
    }
    if(signer == null) {
        signer = Signer.fromSeed("KCMB");
    }

    signer.provider = provider;

    const Abi = {
        koilib_types: tokenlockAbi.types,
        ...tokenlockAbi
    };

    const tokenlockContract = new Contract({
        id: tokenlockmapContract,
        abi: Abi,
        provider,
        signer,
    });

    const result = tokenlockContract.functions;

    return result;
};



export const getTokenFunctions = async (mainnet = false, token = "", signer = null) => {
    let provider = null;

    if (mainnet) {
        provider = new Provider([mainnetProviderUrl]);
    } else {
        provider = await new Provider([harbingerProviderUrl]);
    }
    if(signer == null) {
        signer = Signer.fromSeed("KCMB");
    }

    signer.provider = provider;

    const Abi = {
        koilib_types: tokenAbi.types,
        ...tokenAbi
    };

    const tokenContract = new Contract({
        id: token,
        abi: Abi,
        provider,
        signer,
    });

    const result = tokenContract.functions;

    return result;
};


export function getPresaleStatus(status, text =[], locale = "en") {
    let result = ""
    if (status === 1) {
        result = "Active"
    } else if (status === 2) {
        result = "Launching soon"
    } else if (status === 3) {
        result = "Failed"
    } else if (status === 4) {
        result = "Cancelled"
    } else if (status === 5) {
        result = "Launched"
    } else if (status === 6) {
        result = "Timeout"
    } else {
        result = "Upcoming"
    }

    return result
}

export function getPresaleStatusIcon(status) {
    let result = ""

    if (status === 1) {
        result = "🟢"
    } else if (status === 2) {
        result = "🚀"
    } else if (status === 3) {
        result = "❌"
    } else if (status === 4) {
        result = "🚫"
    } else if (status === 5) {
        result = "🐳"
    } else if (status === 6) {
        result = "⏰"
    } else {
        result = "⌛"
    }

    return result;
}
