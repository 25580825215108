import React, { useEffect, useState } from 'react';
import bigInt from "big-integer";
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Tooltip, Legend,
} from 'recharts';
import styled from 'styled-components';
import {DateTime} from "luxon";
import SimpleLoader from '@/shared/components/SimpleLoader';
import { Button } from '@/shared/components/Button';
import { colorBackground } from '@/utils/palette';
import { right, left, marginRight } from '@/utils/directions';
import axios from 'axios';
import { getPresaleContractFunctions, getTokenFunctions, getTokenlockContractFunctions } from './Functions';
import { formatNumber, getDecimalSeparator } from '../../../../../shared/helpers/numberformats';
import { colorBubble, colorFieldsBorder, colorText, colorTextAdditional, inboxButtonBackgroundHover } from '../../../../../utils/palette';
import Tabs from '../../../../UI/Tabs/components/Tabs';
import { Col, Row, Nav, Tab } from 'react-bootstrap';
import { BorderedTopTabs } from '@/shared/components/Tabs';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';

import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';

const data01 = [{ name: 'New Tasks', value: 40, fill: '#4ce1b6' },
  { name: 'Done Tasks', value: 175, fill: '#70bbfd' },
  { name: 'High Priority Task', value: 70, fill: '#ff4861' }];

const data012 = [{ name: 'New Tasks', value: 95, fill: '#4ce1b6' },
  { name: 'Done Tasks', value: 118, fill: '#70bbfd' },
  { name: 'High Priority Task', value: 59, fill: '#ff4861' }];

const tooltipColor = {
  color: '#70bbfd',
};

const colors = ['#4ce1b6', '#70bbfd', '#ff4861',
  '#1F7A8C', '#7EBDC2', '#75B08B', '#5E9C6F',
  '#F4B266', '#F07A5C', '#D7263D', '#FF9F1C',
  '#FCBF49', '#FF5733', '#FFC300', '#FFD700',
  '#0A043C', '#0A0433', '#3E1F51', '#2D3047',
  '#E94560', '#7EBDC2', '#00B2CA', '#D4AF37',
  '#ff0000', '#ff9900', '#cbff00', '#33ff00',
  '#00ff66', '#00ffff', '#0066ff', '#3200ff',
  '#cc00ff', '#ff0098'

]

const burnAddress1 = '1CounterpartyXXXXXXXXXXXXXXXUWLpVr'
const burnAddress2 = '1111111111111111111114oLvT2'
const stakingRewardsAddress = '1JpcbhG3PtPyDS8yumjoBC4EPHnN9tQVwQ'

export function getPercentage(total, partial){
  return Number(partial / total * 100).toFixed(2)
}

function getContentLiquidityLock(data) {
  const parsedData = Number(data);

  if (!isNaN(parsedData)) {
    if (parsedData < 0) {
      return "expired";
    }
    return parsedData;
  }

  if (!!data) {
    return data;
  }

  return "none";
}



function getDaysUntil(value){
  if(value !== undefined) {
    const now = DateTime.now()
    const unlockDate = DateTime.fromMillis(Number(value))
    const days = Math.floor(unlockDate.diff(now, 'days').days);
    return getDecimalSeparator(days)
  }
  return value
}

function getDaysUntilMessage(endDate) {
  const daysUntil = getDaysUntil(endDate);

  // If the daysUntil is less than 0, return an empty string
  if (daysUntil < 0) {
    return "";
  }

  // Return "day" if 1 day is left, otherwise "days"
  return daysUntil === 1 ? "day" : "days";
}


async function getTokenLocks(launchpadData){
  const locks = []
  const lockItems = []
  const contractFunctions = await getTokenlockContractFunctions(true)
  const pContractFunctions = await getPresaleContractFunctions(true)
  try {
    const pair = (await pContractFunctions.getKoinDxPair({
      value: launchpadData.sub_contract
    })).result.value;
    const liquiditylocksCount =  (await contractFunctions.getMyTokenLocksCount({
      value: pair
    })).result;

    if(liquiditylocksCount !== undefined){
      const tokenFunctions = await getTokenFunctions(true, pair)
      const pairTotalSupply = (await tokenFunctions.total_supply({})).result.value;
      const liquidityLocks = (await contractFunctions.getMyTokenLocks({
        address: pair,
        start: liquiditylocksCount.value,
        limit: liquiditylocksCount.value,
        direction: 1
      })).result.value;

      for (let i = 0; i < liquidityLocks.length; i++) {
        const params = liquidityLocks[i].lock_params
        const item = params.locks_item
        const info = liquidityLocks[i].token_info
        if(Number(params.blocktime) < Number(item.endDate)) {
          const amount = item.amount
          const percentage = getPercentage(pairTotalSupply, amount)
          locks.push(
              {
                name: info.name,
                symbol: item.description + " (" + Number(percentage).toFixed(2) + '%)',
                amount: "",
                decimals: info.decimals,
                description: info.name,
                percentage: percentage,
                days_value: getContentLiquidityLock(getDaysUntil(item.endDate)),
                days_text: getDaysUntilMessage(item.endDate)
              }
          )
        }
      }

    }

  } catch (e){
  }
  // token locks
  const token = launchpadData.token_address
  const tokenlocksCount =  (await contractFunctions.getMyTokenLocksCount({
    value: token
  })).result;

  let lockedAmount = bigInt(0)
  if(tokenlocksCount !== undefined) {
    const tokenlocks = (await contractFunctions.getMyTokenLocks({
      address: token,
      start: tokenlocksCount.value,
      limit: tokenlocksCount.value,
      direction: 1
    })).result.value;

    for (let i = 0; i < tokenlocks.length; i++) {
      const params = tokenlocks[i].lock_params
      const item = params.locks_item
      const info = tokenlocks[i].token_info
      if(Number(params.blocktime) < Number(item.endDate)) {
        lockItems.push(item)
        const percentage = getPercentage(launchpadData.supply, info.amount)
        locks.push(
            {
              name: info.name,
              symbol: info.symbol,
              amount: Number(Number(item.amount).toFixed(0)) / Math.pow(10, launchpadData.decimals),
              decimals: info.decimals,
              description: item.description,
              days_value: getContentLiquidityLock(getDaysUntil(item.endDate)),
              days_text: getDaysUntilMessage(item.endDate)
            }
        )
      }
    }
  }

  return {locks, lockItems}
}

const Statistics = ({ dir, launchpadData, evaluationData, updateSummaryScore }) => {
  const [update, setUpdate] = useState(false);
  const [data1, setData1] = useState(data01);
  const [data, setData] = useState([])
  const [tokenlocks, setTokenlocks] = useState([])
  const [tabs, setTabs] = useState([])
  const [counter, setCounter] = useState(0);
  const [fundings, setFundings] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [unlockedTokens, setUnlockedTokens] = useState(5);
  const [hasLockedTokens, setHasLockedTokens] = useState(false);
  const [lockedTokens, setLockedTokens] = useState(0);
  const [maxScore, setMaxScore] = useState(25);
  const [totalScore, setTotalScore] = useState(-1);

  const onUpdate = () => {
    const stateData1 = data1;

    setUpdate(true);
    const data11 = stateData1 === data01 ? data012 : data01;
    setTimeout(() => {
      setUpdate(false);
      setData1(data);
    }, 2000);
  };

  const getFundings = async (id) => {
    try {
      const response = await axios.get(process.env.REACT_APP_DOMAIN + "/api/kct_launchpad_fundings", {
        params: {
          id: id
        },
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.data.success) {
        return response.data.rows;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching fundings:", error);
      return [];
    }
  }

  const getRefunds = async (id) => {
    try {
      const response = await axios.get(process.env.REACT_APP_DOMAIN + "/api/kct_launchpad_refunds", {
        params: {
          id: id
        },
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.data.success) {
        return response.data.rows;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching refunds:", error);
      return [];
    }
  }


  useEffect(async () => {
    let isMounted = true; // Flag to track if component is mounted

    const fetchData = async () => {
      //console.log(launchpadData)
      try {


        const tokensPresale = bigInt(launchpadData.sale_tokens)
        const tokensListing = bigInt(launchpadData.lp_tokens)
        const tokensFees = bigInt(launchpadData.fee_tokens)
        let supply = bigInt(launchpadData.supply)
        let unlocked = bigInt(supply).minus(tokensFees).minus(tokensPresale).minus(tokensListing)
        let presaleTokenPercentage = getPercentage(supply, tokensPresale)
        let listingTokenPercentage = getPercentage(supply, tokensListing)
        let feesTokenPercentage = getPercentage(supply, tokensFees)
        const tokenFunctions = await getTokenFunctions(true, launchpadData.token_address)

        const { locks, lockItems } = await getTokenLocks(launchpadData)

        setTokenlocks(locks);

        let balanceBurn1 = (await tokenFunctions.balance_of({owner: burnAddress1})).result;
        let balanceBurn2 = (await tokenFunctions.balance_of({owner: burnAddress2})).result;
        let balanceStakingRewards = (await tokenFunctions.balance_of({owner: stakingRewardsAddress})).result;

        let balanceBurn1Percentage = null;
        let balanceBurn2Percentage = null;
        let balanceStakingRewardsPercentage = null;

        let burn1 = false;
        let burn2 = false;
        let stake = false;
        let unlocked_save = unlocked


        if (isMounted && tokenFunctions !== null) {

          if(!!balanceBurn1){
            burn1 = true
            balanceBurn1 = bigInt(balanceBurn1.value)
            unlocked = bigInt(unlocked).minus(balanceBurn1)
            balanceBurn1Percentage = getPercentage(supply, balanceBurn1)
          } else {
            balanceBurn1 = 0
            balanceBurn1Percentage = 0
          }
          if(!!balanceBurn2){
            burn2 = true
            balanceBurn2 = bigInt(balanceBurn2.value)
            unlocked = bigInt(unlocked).minus(balanceBurn2)
            balanceBurn2Percentage = getPercentage(supply, balanceBurn2)
          }else {
            balanceBurn2 = 0
            balanceBurn2Percentage = 0
          }
          if(!!balanceStakingRewards){
            stake = true
            balanceStakingRewards = bigInt(balanceStakingRewards.value)
            unlocked = bigInt(unlocked).minus(balanceStakingRewards)
            balanceStakingRewardsPercentage = getPercentage(supply, balanceStakingRewards)
          }else {
            balanceStakingRewards = 0
            balanceStakingRewardsPercentage = 0
          }

          const newData = []
          newData.push({
            name: 'Presale',
            value: Number(Number(tokensPresale).toFixed(0)) / Math.pow(10, launchpadData.decimals) + Number(Number(tokensFees).toFixed(0)) / Math.pow(10, launchpadData.decimals),
            valuePercentage: Number(presaleTokenPercentage) + Number(feesTokenPercentage),
            fill: colors[0],
            locked: false,
          });
          newData.push({
            name: 'Liquidity',
            value: Number(Number(tokensListing).toFixed(0)) / Math.pow(10, launchpadData.decimals),
            valuePercentage: Number(listingTokenPercentage),
            fill: colors[1],
            locked: true,
          });
          /*
          newData.push({
            name: 'Fees',
            value: Number(Number(tokensFees).toFixed(0)) / Math.pow(10, launchpadData.decimals),
            valuePercentage: Number(feesTokenPercentage),
            fill: colors[2],
            locked: false,
          });
           */
          if(stake){
            newData.push({
              name: 'Rewards',
              value: Number(Number(balanceStakingRewards).toFixed(0)) / Math.pow(10, launchpadData.decimals),
              valuePercentage: Number(balanceStakingRewardsPercentage),
              fill: colors[7],
              locked: true,
            });
          }
          if(lockItems.length > 0) {
            let locks_amount = bigInt(0)
            let minLockDate = 9999999999999999999;
            for (let i = 0; i < lockItems.length; i++) {
              const amount = lockItems[i].amount
              locks_amount = locks_amount.add(amount);
              if(Number(lockItems[i].endDate) < minLockDate){
                minLockDate = Number(lockItems[i].endDate);
              }
              console.log(lockItems[i])
            }
            locks_amount = bigInt(locks_amount)
            unlocked = bigInt(unlocked).minus(locks_amount)
            const percentage = getPercentage(supply, locks_amount)
            newData.push({
              name: 'Locked',
              value: Number(Number(locks_amount).toFixed(0)) / Math.pow(10, launchpadData.decimals),
              valuePercentage: Number(percentage),
              fill: colors[8],
              locked: true,
            });
            minLockDate = Math.floor(minLockDate / 1000)
            let endDate;
            if(Number(launchpadData.launchdate) > 0){
              endDate = Math.floor(Number(launchpadData.launchdate) / 1000);
            } else {
              endDate = Math.floor(Number(launchpadData.enddate) / 1000);
            }
            const minLockTime = (minLockDate - endDate) / 60 / 60 / 24
            if(minLockTime <= 0) setLockedTokens(0)
            else if(minLockTime <= 10) setLockedTokens(1)
            else if(minLockTime <= 45) setLockedTokens(2)
            else if(minLockTime <= 180) setLockedTokens(3)
            else if(minLockTime <= 360) setLockedTokens(4)
            else if(minLockTime > 360) setLockedTokens(5)
            else setLockedTokens(0)
            setHasLockedTokens(true)
            setMaxScore(30)
          }


          if(burn1 || burn2){
            let burnValue = bigInt(balanceBurn1).plus(balanceBurn2)
            let burnValuePercentage = getPercentage(supply, burnValue)
            burnValue = Number(Number(burnValue).toFixed(0))

            if(Number(burnValue) > Number(unlocked_save)){
              burnValue = unlocked_save
              burnValuePercentage = getPercentage(supply, unlocked_save)
            }

            newData.push({
              name: 'Burnt',
              value: Number(Number(burnValue).toFixed(0)) / Math.pow(10, launchpadData.decimals),
              valuePercentage: Number(burnValuePercentage),
              fill: colors[32],
              locked: true,
            });
          }

          if(Number(unlocked) > 0) {
            let unlockedPercentage = Number(getPercentage(supply, unlocked))
            newData.push({
              name: 'Team',
              value: Number(Number(unlocked).toFixed(0)) / Math.pow(10, launchpadData.decimals),
              valuePercentage: unlockedPercentage,
              fill: colors[14],
              locked: true,
            });

            if(unlockedPercentage <= 2) setUnlockedTokens(5);
            else if(unlockedPercentage <= 5) setUnlockedTokens(4);
            else if(unlockedPercentage <= 15) setUnlockedTokens(3);
            else if(unlockedPercentage <= 30) setUnlockedTokens(2);
            else if(unlockedPercentage <= 50) setUnlockedTokens(1);
            else setUnlockedTokens(0);
          }

          setData(newData);

        } else {
          console.error('API response was not successful');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Fetch data initially
    await fetchData();

    if(hasLockedTokens) {
      setTotalScore(evaluationData.first_rating + unlockedTokens + lockedTokens)
    } else {
      setTotalScore(evaluationData.first_rating + unlockedTokens)
    }

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [dir, launchpadData]); // Dependency array includes sub_contract

  useEffect(() => {
    const fetchFundings = async () => {
      const fundings = await getFundings(launchpadData.sub_contract);
      setFundings(fundings);
    };

    fetchFundings(); // Fetch refunds once

    // No need to return a cleanup function since there's no interval
  }, [dir, launchpadData]);


  const score = evaluationData.first_rating + lockedTokens + unlockedTokens;
  useEffect(() => {
    if (hasLockedTokens) {
      setTotalScore(evaluationData.first_rating + unlockedTokens + lockedTokens);
    } else {
      setTotalScore(evaluationData.first_rating + unlockedTokens);
    }

    // Call the callback to send score and maxScore to the parent (Summary.jsx)
    if (typeof updateSummaryScore === 'function') {
      updateSummaryScore(score, maxScore);
    }

  }, [score, maxScore, hasLockedTokens, unlockedTokens, lockedTokens, evaluationData]);

  useEffect(() => {
    const fetchRefunds = async () => {
      const refunds = await getRefunds(launchpadData.sub_contract);
      setRefunds(refunds);
    };

    fetchRefunds(); // Fetch refunds once

    // No need to return a cleanup function since there's no interval
  }, [dir, launchpadData, evaluationData]);

  if(totalScore != (evaluationData.first_rating + lockedTokens + unlockedTokens)){
    setTotalScore(evaluationData.first_rating + lockedTokens + unlockedTokens);
  }
  return (
        <Col xs={12} md={12} lg={12} xl={12}>
          <BorderedTopTabs>
            <Tab.Container defaultActiveKey="1">
              <TabsWrap>
                <Nav className="nav-tabs">
                  <NavItem>
                    <NavLink eventKey="1" onClick={() => setCounter((prevCounter) => prevCounter + 1)}>
                      Tokenomics
                    </NavLink>
                  </NavItem>
                  {tokenlocks.length > 0 &&
                    <NavItem>
                      <NavLink eventKey="2">
                        Token locks
                      </NavLink>
                    </NavItem>
                  }
                  {fundings.length > 0 &&
                    <NavItem>
                      <NavLink eventKey="3">
                        Fundings
                      </NavLink>
                    </NavItem>
                  }
                  {refunds.length > 0 &&
                    <NavItem>
                      <NavLink eventKey="4">
                        Refunds
                      </NavLink>
                    </NavItem>
                  }
                  {fundings.filter(item => (item.claimed == true)).length > 0 &&
                      <NavItem>
                        <NavLink eventKey="5">
                          Claims
                        </NavLink>
                      </NavItem>
                  }
                  {!!evaluationData.audit &&
                      <NavItem>
                        <NavLink eventKey="6">
                          Ratings
                        </NavLink>
                      </NavItem>
                  }
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="1" key={"pie_" + counter} >

                    <ProjectStatistics key={"LS_1"}>

                      <Col xs={12} md={12} lg={12} xl={6}>
                        <ProjectStatisticChartWrap dir={dir}>
                          <PieChart width={300} height={270}>
                            <Tooltip itemStyle={tooltipColor} />
                            <Pie data={data} key={launchpadData.seq_num} dataKey="value" cx="50%" cy={110} innerRadius={60} outerRadius={80} label />
                            <Legend />
                          </PieChart>
                        </ProjectStatisticChartWrap>
                      </Col>

                      <Col xs={12} md={12} lg={12} xl={6}>

                        <ProjectStatisticTokenomicsWrap dir={dir}>
                          {!!data && data.map((tokenomics, index) => (
                              <TokenItem key={"LS1_d"+index}>
                                <TokenItemTitle>
                                  <label>
                                    <ColorBox style={{ backgroundColor: `${tokenomics.fill}` }}></ColorBox>
                                    {tokenomics.name}
                                  </label>
                                  <label>
                                    Allocation
                                  </label>
                                </TokenItemTitle>
                                <TokenItemValue>
                                  <label>
                                    {tokenomics?.value} {launchpadData.symbol}
                                  </label>
                                  <label>
                                    {tokenomics?.valuePercentage.toFixed(2)} %
                                  </label>
                                </TokenItemValue>
                              </TokenItem>
                          ))}
                        </ProjectStatisticTokenomicsWrap>
                      </Col>
                    </ProjectStatistics>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <LockStatistics key={"LS_2"}>
                      <ProjectStatisticTokenomicsWrap dir={dir}>
                        {!!tokenlocks && tokenlocks.map((locks, index) => (
                            <TokenItem key={"LS2_t_"+index}>
                              <Col xs={8} md={9} lg={8} xl={9}>
                                <TokenItemTitle>
                                  🔒 {locks.description}
                                </TokenItemTitle>
                                <TokenItemValue>
                                  {locks?.amount} {locks?.symbol}
                                </TokenItemValue>
                              </Col>
                              <Col xs={4} md={3} lg={4} xl={3} style={{textAlign: 'right'}}>
                                <TokenItemTitle>
                                  Unlock in
                                </TokenItemTitle>
                                <TokenItemValue>
                                  {locks?.days_value} {locks?.days_text}
                                </TokenItemValue>
                              </Col>
                            </TokenItem>
                        ))}
                      </ProjectStatisticTokenomicsWrap>
                    </LockStatistics>

                  </Tab.Pane>
                  <Tab.Pane eventKey="3">

                    <LockStatistics key={"LS_3"}>
                      <ProjectStatisticTokenomicsWrap dir={dir}>

                        {!!fundings && fundings.map((funding, index) => (
                            <TokenItem key={"LS3_f_"+index}>
                              <Col xs={8} md={9} lg={8} xl={9}>
                                <TokenItemTitle>
                                  {index + 1}: {funding?.contributor}
                                </TokenItemTitle>
                                <TokenItemValue>
                                  {funding?.contribution} KOIN
                                </TokenItemValue>
                              </Col>
                              <Col xs={4} md={3} lg={4} xl={3} style={{textAlign: 'right'}}>
                                <TokenItemTitle>
                                  Allocation
                                </TokenItemTitle>
                                <TokenItemValue>
                                  {funding?.percentage_contribution} %
                                </TokenItemValue>
                              </Col>
                            </TokenItem>
                        ))}
                      </ProjectStatisticTokenomicsWrap>
                    </LockStatistics>

                  </Tab.Pane>
                  <Tab.Pane eventKey="4">

                    <LockStatistics key={"LS_4"}>
                      <ProjectStatisticTokenomicsWrap dir={dir}>
                        {!!refunds && refunds.map((item, index) => (
                            <TokenItem key={"LS4_r_"+index}>
                              <Col xs={8} md={9} lg={8} xl={9}>
                                <TokenItemTitle>
                                  {index + 1}: {item?.contributor}
                                </TokenItemTitle>
                                <TokenItemValue>
                                  {item?.contribution} KOIN
                                </TokenItemValue>
                              </Col>
                              <Col xs={4} md={3} lg={4} xl={3} style={{textAlign: 'right'}}>
                                <TokenItemTitle>
                                  Allocation
                                </TokenItemTitle>
                                <TokenItemValue>
                                  {item?.percentage_contribution} %
                                </TokenItemValue>
                              </Col>
                            </TokenItem>
                        ))}
                      </ProjectStatisticTokenomicsWrap>
                    </LockStatistics>

                  </Tab.Pane>
                  <Tab.Pane eventKey="5">

                    <LockStatistics key={"LS_5"}>
                      <ProjectStatisticTokenomicsWrap dir={dir}>
                        {!!fundings && fundings.filter(item => (item.claimed == true)).map((item, index) => (
                            <TokenItem key={"LS5_f_"+index}>
                              <Col xs={8} md={9} lg={8} xl={9}>
                                <TokenItemTitle>
                                  {index + 1}: {item?.contributor}
                                </TokenItemTitle>
                                <TokenItemValue>
                                  {item?.contribution} KOIN
                                </TokenItemValue>
                              </Col>
                              <Col xs={4} md={3} lg={4} xl={3} style={{textAlign: 'right'}}>
                                <TokenItemTitle>
                                  Allocation
                                </TokenItemTitle>
                                <TokenItemValue>
                                  {item?.percentage_contribution} %
                                </TokenItemValue>
                              </Col>
                            </TokenItem>
                        ))}
                      </ProjectStatisticTokenomicsWrap>
                    </LockStatistics>

                  </Tab.Pane>


                  <Tab.Pane eventKey="6">

                    <LockStatistics key={"LS_6"}>
                      <ProjectStatisticTokenomicsWrap dir={dir}>
                        {totalScore != -1 &&
                            <ProgressEvaluationTable>
                              <thead>
                              <tr>
                                <th>Rating \ Score</th>
                                <th>0</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <th>Sale duration</th>
                                <td className={evaluationData.duration == 0 ? "_active": ""}>else</td>
                                <td className={evaluationData.duration == 1 ? "_active": ""}>{`<=90d`}</td>
                                <td className={evaluationData.duration == 2 ? "_active": ""}>{`<=30d`}</td>
                                <td className={evaluationData.duration == 3 ? "_active": ""}>{`<=14d`}</td>
                                <td className={evaluationData.duration == 4 ? "_active": ""}>{`<=7d`}</td>
                                <td className={evaluationData.duration == 5 ? "_active": ""}>{`<=2d`}</td>
                              </tr>
                              <tr>
                                <th>LP-Lock</th>
                                <td className={evaluationData.lp_lock == 0 ? "_active": ""}>none</td>
                                <td className={evaluationData.lp_lock == 1 ? "_active": ""}>{`<=45d`}</td>
                                <td className={evaluationData.lp_lock == 2 ? "_active": ""}>{`<=180d`}</td>
                                <td className={evaluationData.lp_lock == 3 ? "_active": ""}>{`<=360d`}</td>
                                <td className={evaluationData.lp_lock == 4 ? "_active": ""}>{`<=720d`}</td>
                                <td className={evaluationData.lp_lock == 5 ? "_active": ""}>{`else`}</td>
                              </tr>
                              <tr>
                                <th>Sale / Listing price</th>
                                <td className={evaluationData.sale_listing_rate == 0 ? "_active": ""}>{`else`}</td>
                                <td className={evaluationData.sale_listing_rate == 1 ? "_active": ""}>{`<=100%`}</td>
                                <td className={evaluationData.sale_listing_rate == 2 ? "_active": ""}>{`<=50%`}</td>
                                <td className={evaluationData.sale_listing_rate == 3 ? "_active": ""}>{`<=30%`}</td>
                                <td className={evaluationData.sale_listing_rate == 4 ? "_active": ""}>{`<=15%`}</td>
                                <td className={evaluationData.sale_listing_rate == 5 ? "_active": ""}>{`<=5%`}</td>
                              </tr>
                              <tr>
                                <th>LP-Fundings</th>
                                <td className={evaluationData.lp_percentage == 0 ? "_active": ""}>{`<52%`}</td>
                                <td className={evaluationData.lp_percentage == 1 ? "_active": ""}>{`<60%`}</td>
                                <td className={evaluationData.lp_percentage == 2 ? "_active": ""}>{`<75%`}</td>
                                <td className={evaluationData.lp_percentage == 3 ? "_active": ""}>{`<90%`}</td>
                                <td className={evaluationData.lp_percentage == 4 ? "_active": ""}>{`<98%`}</td>
                                <td className={evaluationData.lp_percentage == 5 ? "_active": ""}>{`else`}</td>
                              </tr>
                              <tr>
                                <th>Unlocked Tokens</th>
                                <td className={unlockedTokens == 0 ? "_active": ""}>{`>50%`}</td>
                                <td className={unlockedTokens == 1 ? "_active": ""}>{`<=50%`}</td>
                                <td className={unlockedTokens == 2 ? "_active": ""}>{`<=30%`}</td>
                                <td className={unlockedTokens == 3 ? "_active": ""}>{`<=15%`}</td>
                                <td className={unlockedTokens == 4 ? "_active": ""}>{`<=5%`}</td>
                                <td className={unlockedTokens == 5 ? "_active": ""}>{`<=2%`}</td>
                              </tr>
                              {hasLockedTokens &&
                                <tr>
                                  <th>Locked Tokens</th>
                                  <td className={lockedTokens == 0 ? "_active": ""}>else</td>
                                  <td className={lockedTokens == 1 ? "_active": ""}>{`<=10d`}</td>
                                  <td className={lockedTokens == 2 ? "_active": ""}>{`<=45d`}</td>
                                  <td className={lockedTokens == 3 ? "_active": ""}>{`<=180d`}</td>
                                  <td className={lockedTokens == 4 ? "_active": ""}>{`<=360d`}</td>
                                  <td className={lockedTokens == 5 ? "_active": ""}>{`>360d`}</td>
                                </tr>
                              }
                              <tr>
                                <td colSpan={7}>Automatic Calculated Score: {score} / {maxScore}</td>
                              </tr>
                              </tbody>
                            </ProgressEvaluationTable>
                        }
                      </ProjectStatisticTokenomicsWrap>
                    </LockStatistics>

                  </Tab.Pane>
                </Tab.Content>
              </TabsWrap>
            </Tab.Container>
          </BorderedTopTabs>

        </Col>
  );
};

Statistics.propTypes = {
  dir: PropTypes.string.isRequired,
  evaluationData: PropTypes.object.evaluationData,
  launchpadData: PropTypes.object.isRequired,
  updateSummaryScore: PropTypes.func.isRequired,
};

export default Statistics;


const ProgressEvaluationTable = styled.table`
  width: 100%;
  text-align: center;
  border-top: 1px solid ${colorTextAdditional};
  
  th, td {
    padding: 5px;
  }
  
  th{
    font-weight: 500;
    color: ${colorTextAdditional};
  }
  
  td {
    font-weight: 300;
    color: ${colorTextAdditional};
  }


  thead {
    border-bottom: 1px solid ${colorTextAdditional};
  }
  
  tbody {
    border-bottom: 1px solid ${colorTextAdditional};
  }

  tbody tr {
    border-bottom: 1px solid ${colorFieldsBorder};
  }

  tbody tr:last-of-type {
    border-bottom: 1px solid ${colorTextAdditional};
  }

  tbody th:after{
    content: ":";
  }
  
  
  th:first-child {
    text-align: right;
    width: 130px;
  }

  thead tr th:first-of-type{
    font-weight: 500;
  }
  
  ._active{
    /*background-color: green;*/
    color: ${colorText};
    font-weight: unset;
    background: ${inboxButtonBackgroundHover};
  }
`;

// region STYLES

const ProjectStatistics = styled.div`
  text-align: ${left};
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const TokenItem = styled.div`
  text-align: ${left};
  display: flex;
  flex-wrap: wrap;
  position: relative;
  border: 1px solid rgba(200,200,200, 0.03);
  background-color: rgba(200,200,200, 0.05);
  width: 100%;
  margin: 5px 8px 5px 8px;
  padding: 8px 10px 8px 10px;
  border-radius: 15px;

  color: ${colorText};
`;
const ColorBox = styled.div`
  border: 1px solid #252525;
  border-radius: 4px;
  display: block;
  float: left;
  height: 12px;
  margin-right: 6px;
  margin-top: calc(1em - 7px);
  width: 12px;
`;
const TokenItemTitle = styled.div`
  display: block;
  width: 100%;
  height: 2em;
  line-height: 2em;
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Ensures that overflowing text is hidden */
  text-overflow: ellipsis; /* Adds the ellipsis (...) when the text overflows */

  label:first-child{
    float: left;
  }
  label:nth-child(2){
    float: right;
  }
`;

const TokenItemValue = styled.div`
  display: block;
  width: 100%;
  vertical-align: middle;
  height: 2em;
  line-height: 2em;
  opacity: 0.6;
  font-weight: 300;

  label:first-child{
    float: left;
  }
  label:nth-child(2){
    float: right;
  }
`;

const ProjectStatistic = styled.div`
  padding-top: 40px;
  width: 47%;
  ${marginRight}: 5px;

  .recharts-wrapper {
    direction: ltr;

    .recharts-legend-wrapper ul.recharts-default-legend {
      text-align: center;
      line-height: 24px;

      .recharts-legend-item .recharts-surface {
        ${marginRight}: 4px;
      }
    }
  }

  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

const LockStatistics = styled.div`
  padding-top: 40px;
  width: 94%;
  ${marginRight}: 5px;

  .recharts-wrapper {
    direction: ltr;
    
    .recharts-legend-wrapper ul.recharts-default-legend {
      text-align: center;
      line-height: 24px;

      .recharts-legend-item .recharts-surface {
        ${marginRight}: 4px;
      }
    }
  }
  
  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

const ProjectStatisticTitle = styled.p`
  text-transform: uppercase;
  /*margin-bottom: 25px;*/
  font-weight: 500;  
`;

const ProjectStatisticChartWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const ProjectStatisticTokenomicsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProjectStatisticButton = styled(Button)`
  position: absolute;
  top: 0;
  ${right}: 15px;
`;

const ProjectStatisticRefresh = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  ${left}: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    ${left}: 0;
    border-radius: 5px;
    background-color: ${colorBackground};
    opacity: 0.8;
  }

  svg {
    position: absolute;
    top: calc(50% - 24px);
    ${left}: calc(50% - 24px);
  }
`;

// endregion
