import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import renderDateTimePicker from '@/shared/components/form/date-pickers/DateTimePicker';
import {
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupIcon,
    FormGroupLabel,
} from '@/shared/components/form/FormElements';
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";

const DatePickers = ({ onSubmit }) => {
    const { t } = useTranslation('common');

    return (
        <Col xs={12} md={12} lg={12} xl={12}>
            <FormContainer>
                <FormGroup>
                    <FormGroupLabel><b>Lock Liquidity (Recommended)</b>: Lock the liquidity to secure investor trust.</FormGroupLabel>
                    <FormGroupField>
                        <Field
                            name="liquidity_lock_date"
                            placeholder="EX: 1"
                            component={renderDateTimePicker}
                        />
                        <FormGroupIcon>
                            <CalendarBlankIcon />
                        </FormGroupIcon>
                    </FormGroupField>
                </FormGroup>
            </FormContainer>
        </Col>
    );
};

DatePickers.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default DatePickers;
