import React, { useState, useEffect } from 'react';
import ConnectModal from "@/shared/components/wallet/components/ConnectModal";
import DisconnectButton from "@/shared/components/wallet/components/DisconnectButton";

const TopbarWallet = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isConnectedKondor, setIsConnectedKondor] = useState(localStorage.getItem('connectedKondor') === 'true');
  const [isConnectedWC, setIsConnectedWC] = useState(localStorage.getItem('connectedWC') === 'true');


  // Check if something changed in localStorage every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      const connectedKondor = localStorage.getItem('connectedKondor') === 'true';
      const connectedWC = localStorage.getItem('connectedWC') === 'true';

      if (connectedKondor !== isConnectedKondor) {
        setIsConnectedKondor(connectedKondor);
      }

      if (connectedWC !== isConnectedWC) {
        setIsConnectedWC(connectedWC);
      }
    }, 750); // Check every 3 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [isConnectedKondor, isConnectedWC]);

  if (isConnectedWC || isConnectedKondor) {
    return <DisconnectButton />;
  } else {
    return <ConnectModal />;
  }
};

export default TopbarWallet;
