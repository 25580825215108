import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CatalogItems from './components/CatalogItems';
import styled from 'styled-components';

const Catalog = () => (
  <LaunchpadContainer>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Launchpads</h3>
        <h3 className="page-subhead subhead">
            View all launchpads and their details. You can filter, sort, and search for launchpads by
            name, symbol and contract.
        </h3>
      </Col>
    </Row>
    <Row>
      <CatalogItems />
    </Row>
  </LaunchpadContainer>
);

const LaunchpadContainer = styled.div`
  width: calc(100% - 15px);
`;

export default Catalog;
