export const formGroup = [
  { color: '#4ce1b6', defaultChecked: true },
  { color: '#70bbfd' },
  { color: '#fa4a86' },
  { color: '#f6da6e' },
  { color: '#7ed321' },
  { color: '#b635ba' },
  { color: '#ff4861' },
  { color: '#38c3d7' },
  { color: '#f98330' },
];

export const checkboxGroup = [
  {
    name: 'checkbox_one', label: 'Checkbox 1', defaultChecked: true,
  }, {
    name: 'checkbox_two', label: 'Checkbox 2',
  }, {
    name: 'checkbox_disable', label: 'Checkbox Disabled', disabled: true,
  }, {
    name: 'checkbox_disable_check', label: 'Disabled & checked', defaultChecked: true, disabled: true,
  },
];

export const radioButtonsGroup = [
  {
    name: 'radio_button', label: 'Radio button 1', radioValue: '1', initialValue: '1',
  }, {
    name: 'radio_button', label: 'Radio button 2', radioValue: '2',
  }, {
    name: 'radio_disabled_button', label: 'Radio button disabled', radioValue: '1', disabled: true,
  }, {
    name: 'radio_disabled_button', label: 'Disabled & checked', radioValue: '2', initialValue: '2', disabled: true,
  },
];

export const radioButtonsGroupKoincityPackage1 = [
  {
    name: 'radio_button_package', label: 'Creator Package 1', radioValue: '1',
  }
];

export const radioButtonsGroupKoincityPackage2 = [
  {
    name: 'radio_button_package', label: 'Creator Package 2 (recommended)', radioValue: '2',
  }
];

export const radioButtonsGroupKoincityToken = [
  {
    name: 'radio_button_token', label: 'I have a Token', description: 'Great! You’ll just need to provide your token address.', radioValue: 'softcapHardcap',
  },
  {
    name: 'radio_button_token', label: 'Create a New Token (Recommended)', description: 'New to this? No worries! We’ll guide you through the creation of your token, ensuring it’s set up for success.', radioValue: 'fairLaunch',
  }
];


export const radioButtonsGroupLaunchType = [
  {
    name: 'radio_button_launch_type', label: 'Softcap / Hardcap', description: 'The Softcap is the minimum amount of funds you aim to raise, and the Hardcap represents the maximum amount of funds that can be raised during the sale.', radioValue: 'softcapHardcap',
  },
  {
    name: 'radio_button_launch_type', label: 'Fair Launch', description: 'The Fair Launch is a sale with the aim to raise Softcap and possibly more; this model has no Hardcap.', radioValue: 'fairLaunch',
  }
];


export const textDescriptionContribution = [
  {
    name: 'radio_button_contribution', label: 'Minimum Contribution', description: 'Set a floor to ensure that the investment is accessible to a diverse group of participants.',
  },
  {
    name: 'radio_button_contribution', label: 'Maximum Contribution', description: 'Establish a ceiling to avoid excessive concentration of ownership, fostering a healthier, more balanced investor base.',
  }
];

export const radioWhoParticipate = [
  {
    name: 'radio_button_who_participate', label: 'Accessible for Everyone', description: 'Open your presale to all interested parties.', radioValue: 'all',
  },
  {
    name: 'radio_button_who_participate', label: 'Only for Koinos Garden NFT Holders', description: 'Limit participation to NFT holders, creating an exclusive opportunity.', radioValue: 'koinos_garden',
  }
];

export const radioUnsoldTokens = [
  {
    name: 'radio_button_unsold_tokens', label: 'Burn (Recommended)', description: 'Permanently remove unsold tokens from circulation.', radioValue: 'all',
  },
  {
    name: 'radio_button_unsold_tokens', label: 'Refund', description: 'Return unsold tokens to the project team.', radioValue: 'koinos_garden',
  }
];
