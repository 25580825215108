import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Card, CardBody } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';

const Entry = () => (
    <LaunchpadContainer>
        <Row>
            <Col md={12}>
                <PageTitle>Worlds at War (WAR)</PageTitle>
                <PageSubhead>
                    Introducing the WAR Token, a revolutionary altcoin designed for the modern digital battleground. WAR isn't just another cryptocurrency; it's a symbol of resilience, strategy, and community strength in the face of digital warfare.
                </PageSubhead>
                <PageSubhead>
                    We aim to build a GameFi suite powered by the $WAR token. Gamers will be able to trade their tokens for in-game items and exclusive features with zero transaction fees, thanks to the feeless design of the Koinos network.
                </PageSubhead>
                <PageSubhead>Built by gamers, for gamers.</PageSubhead>
            </Col>
        </Row>

        <Row>
            <Col md={8} sm={12}>
                <TokenDetailsCard>
                    <CardBody>
                        <SectionTitle>Token Information</SectionTitle>
                        <TokenDetailsList>
                            <li><Dot />Token name: Worlds at War</li>
                            <li><Dot />Token symbol: WAR</li>
                            <li><Dot />Decimals: 8</li>
                            <li><Dot />Total supply: 95,000</li>
                            <li><Dot />Token address: 172SaSjSD4xP2PEmg64p9KBDNqTSQhDD9xz</li>
                            <li><Dot />Sale WAR per Koin: 30.150754</li>
                            <li><Dot />Liquidity pool: 50%</li>
                            <li><Dot />Softcap: 1,000 Koin</li>
                            <li><Dot />Liquidity lock: 1,478</li>
                        </TokenDetailsList>
                    </CardBody>
                </TokenDetailsCard>

                <TokenomicsCard>
                    <CardBody>
                        <SectionTitle>Tokenomics</SectionTitle>
                        <TokenomicsWrapper>
                            <TokenomicsChart>
                                <img src="tokenomics-chart.png" alt="Tokenomics Pie Chart" />
                            </TokenomicsChart>
                            <TokenomicsList>
                                <ul>
                                    <li>Sale: 60,000 (63.16%)</li>
                                    <li>Listing: 17,500 (18.42%)</li>
                                    <li>Fees: 2,400 (2.53%)</li>
                                    <li>Ecosystem development 5: 2,000 (2.11%)</li>
                                    <li>Ecosystem development 4: 2,000 (2.11%)</li>
                                    <li>Ecosystem development 3: 2,000 (2.11%)</li>
                                    <li>Ecosystem development 2: 2,000 (2.11%)</li>
                                    <li>Ecosystem development 1: 2,000 (2.11%)</li>
                                </ul>
                            </TokenomicsList>
                        </TokenomicsWrapper>
                    </CardBody>
                </TokenomicsCard>

                <TokenLocksCard>
                    <CardBody>
                        <SectionTitle>Token Locks</SectionTitle>
                        <TokenLocksList>
                            <ul>
                                <li>Ecosystem development 5: Unlock in 321 days</li>
                                <li>Ecosystem development 4: Unlock in 260 days</li>
                                <li>Ecosystem development 3: Unlock in 197 days</li>
                                <li>Ecosystem development 2: Unlock in 140 days</li>
                                <li>Ecosystem development 1: Unlock in 78 days</li>
                                <li>Team tokens: Unlock in 1830 days</li>
                            </ul>
                        </TokenLocksList>
                    </CardBody>
                </TokenLocksCard>
            </Col>

            <Col md={4} sm={12}>
                <BuySection>
                    <FixedBuyCard>
                        <SectionTitle>Fair Launch</SectionTitle>
                        <LaunchDetailsList>
                            <li>Minimum: 5 Koin</li>
                            <li>Maximum: 200 Koin</li>
                            <li>My contribution: 0 Koin</li>
                            <li>My tokens: 0 WAR</li>
                            <li>Entries: 25</li>
                            <li>Audit: Passed</li>
                        </LaunchDetailsList>
                        <ProgressContainer>
                            <ProgressBar>
                                <ProgressFiller style={{ width: '100%' }} />
                            </ProgressBar>
                            <ProgressText>100% funded (1,990 / 1,000 Koin)</ProgressText>
                        </ProgressContainer>
                        <ButtonContainer>
                            <StyledButton>Buy</StyledButton>
                        </ButtonContainer>
                    </FixedBuyCard>
                </BuySection>
            </Col>
        </Row>
    </LaunchpadContainer>
);

const TokenDetailsList = styled.ul`
  list-style-type: none; /* Remove default list style */
  padding: 0; /* Remove padding */
  color: #fff; /* White text for readability on dark background */
  font-size: 16px; /* Set a readable font size */
  line-height: 1.8; /* Improve line spacing for better readability */
  margin: 15px 0; /* Add margin between items and surrounding content */

  li {
    margin-bottom: 10px; /* Space between list items */
    display: flex; /* Align items horizontally */
    align-items: center; /* Vertically center list items */
  }
`;

const LaunchpadContainer = styled.div`
    width: 100%;
    padding: 20px;
    background-color: #121212;
`;

const PageTitle = styled.h3`
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
`;

const PageSubhead = styled.h4`
    color: #b0b0b0;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.6;
`;

const SectionTitle = styled.h4`
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const TokenDetailsCard = styled(Card)`
    background-color: #1e1e1e;
    color: #fff;
    border: 1px solid #333;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;

const TokenomicsCard = styled(Card)`
    background-color: #1e1e1e;
    color: #fff;
    border: 1px solid #333;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;

const TokenLocksCard = styled(Card)`
    background-color: #1e1e1e;
    color: #fff;
    border: 1px solid #333;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;

const TokenomicsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TokenomicsChart = styled.div`
    width: 50%;
    img {
        width: 100%;
    }
`;

const TokenomicsList = styled.div`
    width: 50%;
    ul {
        list-style-type: none;
        padding: 0;
        color: #fff;
        font-size: 16px;
        line-height: 1.8;

        li {
            margin-bottom: 10px;
        }
    }
`;

const TokenLocksList = styled.div`
    ul {
        list-style-type: none;
        padding: 0;
        color: #fff;
        font-size: 16px;
        line-height: 1.8;

        li {
            margin-bottom: 10px;
        }
    }
`;

const Dot = styled.span`
    height: 8px;
    width: 8px;
    background-color: #4caf50;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
`;

const BuySection = styled.div`
    position: sticky;
    top: 20px;
`;

const FixedBuyCard = styled(Card)`
  background-color: #292929;
  color: #fff;
  border: 1px solid #444;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const LaunchDetailsList = styled.ul`
  list-style-type: none;
  padding: 0;
  color: #fff;
  line-height: 1.8;

  li {
    margin-bottom: 10px;
  }
`;

const ProgressContainer = styled.div`
  margin-top: 20px;
`;

const ProgressBar = styled.div`
  background-color: #444;
  border-radius: 10px;
  width: 100%;
  height: 12px;
`;

const ProgressFiller = styled.div`
  background-color: #28a745;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
`;

const ProgressText = styled.p`
  color: #fff;
  font-size: 14px;
  margin-top: 5px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  background-color: #ff5722;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e64a19;
  }
`;

export default Entry;
