import React, { Component } from "react";
import "./KapInfoChat.css";
import {Contract, Provider, Signer} from "koilib";
import {mainnetNicknamesContract} from "./kap.ts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faGlobe} from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faGithub, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Avatar from './avatar'
import nicknamesAbi from "@/shared/components/wallet/ABI/nicknames-abi.json";
import {mainnetProviderUrl} from "@/shared/constants/koincity-constants";


class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: null,
            links: [],
            avatar: null,
            avatarDefault: false,
            bio: null,
            name: null,
            loading: true,
            noActiveAccount: false
        }
    }
    isMainnet(){
        return !this.props.testnet;
    }



    async componentDidMount(){
        let provider = new Provider([mainnetProviderUrl]);
        let nicknamesContract = mainnetNicknamesContract

        const signer = Signer.fromSeed("my seed");
        signer.provider = provider;

        const Abi = {
            koilib_types: nicknamesAbi.types,
            ...nicknamesAbi
        }

        const koinContract = new Contract({
            id: nicknamesContract,
            abi: Abi,
            provider,
            signer,
        });

        const koin = koinContract.functions;
        const hexOwner = "0x" + Buffer.from(this.props.owner).toString('hex')

        const metadata = (await koin.metadata_of({
            token_id: hexOwner
        })).result.value;
        const metaDataObject = JSON.parse(metadata)


        await this.setState({
            result: metaDataObject
        })

        if(!!metaDataObject){
            this.prepareView(metaDataObject, this.props.owner)
        } else {
            await this.setState({
                noActiveAccount: true,
                loading: false
            })
        }
    }


    async prepareView(data, name){
        let links = []
        let avatar = null
        let avatarDefault = false
        let bio = null

        if(!!data && !!data['discord']) {
            let discord = data['discord']
            if (discord.startsWith('http://') || discord.startsWith('https://')) {
                links.discord = discord;
            } else {
                links.discord = 'https://discord.com/users/' + discord;
            }
        }
        if(!!data && !!data['email']) {
            links.email = data['email']
        }
        if(!!data && !!data['github']) {
            let github = data['github']
            if (github.startsWith('http://') || github.startsWith('https://')) {
                links.github = github;
            } else {
                links.github = 'https://github.com/' + github;
            }
        }
        if(!!data && !!data['telegram']) {
            let telegram = data['telegram']
            if (telegram.startsWith('http://') || telegram.startsWith('https://')) {
                links.telegram = telegram;
            } else {
                links.telegram = 'https://t.me/' + telegram;
            }
        }
        if(!!data && !!data['twitter']) {
            let twitter = data['twitter']
            if (twitter.startsWith('http://') || twitter.startsWith('https://')) {
                links.twitter = twitter;
            } else {
                links.twitter = 'https://twitter.com/' + twitter;
            }
        }
        if(!!data && !!data['website']) {
            let website = data['website']
            if (website.startsWith('http://') || website.startsWith('https://')) {
                links.website = website;
            } else {
                links.website = 'https://' + website;
            }
        }

        if(!!data && !!data['bio']) {
            bio = data['bio']
        }
        if(!!data && !!data['image']) {
            avatar = data['image']
        } else {
            avatarDefault = true
        }

        await this.setState({
            links,
            bio,
            name,
            avatar,
            avatarDefault,
            loading: false
        })
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.testnet !== this.props.testnet || prevProps.owner !== this.props.owner){
            await this.setState({
                result: null,
                links: [],
                avatar: null,
                avatarDefault: false,
                bio: null,
                name: null,
                loading: true,
                noActiveAccount: false
            })
            this.componentDidMount()
        }

    }

    render() {
        const links = this.state.links
        const owner = this.props.owner
        const avatar = this.state.avatar
        const loading = this.state.loading
        const avatarDefault = this.state.avatarDefault
        const bio = this.state.bio
        const name = this.state.name
        const noActiveAccount = this.state.noActiveAccount
        return (

            <div className={"walletConnectedKapInfoChat noselect"}>
                {!!avatarDefault &&
                    <div className={"walletConnectedKapInfoProfile"}>
                        <Avatar seed={owner}/>
                    </div>
                }
                {!!avatar &&
                    <div className={"walletConnectedKapInfoProfile"}>
                        <img alt={"avatar"} className={"walletConnectedKapInfoProfilePic noselect"} src={avatar}/>
                    </div>
                }
                {!!name &&
                    <div className={"walletConnectedKapInfoDomain noselect"}>
                        <div className={"headerWalletConnectorModalContentKapTitleChat"}>
                            <img alt="Nicknames" className={"headerWalletConnectorModalContentWrapperKapLogo"} src={"/partners/nicknames.svg"}/>
                        </div> {name}
                    </div>
                }
                {!!bio &&
                    <div className={"walletConnectedKapInfoBio noselect"}>
                        {bio}
                    </div>
                }
                {Object.keys(links).length > 0 &&
                    <div className={"walletConnectedKapInfoIcons"}>
                        {!!links.discord &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={links.discord}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faDiscord}/>
                            </a>
                        }
                        {!!links.email &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"mailto:" + links.email}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faEnvelope}/>
                            </a>
                        }
                        {!!links.github &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={ links.github}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faGithub}/>
                            </a>
                        }
                        {!!links.telegram &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={links.telegram}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faTelegram}/>
                            </a>
                        }
                        {!!links.twitter &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={links.twitter}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faTwitter}/>
                            </a>
                        }
                        {!!links.website &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={links.website}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faGlobe}/>
                            </a>
                        }
                    </div>
                }
                {loading &&
                    <div className={"walletConnectedKapLoading noselect"}>
                        <img alt={"loading"} src={"/profile-loader1.gif"}/>
                    </div>
                }
                {noActiveAccount &&
                    <div className={"walletConnectedNoActiveKapAccount noselect"}>
                        <p>no account data</p>
                    </div>
                }
            </div>
        )
    }
}

export default Landing;


