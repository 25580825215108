import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ProgressBar from '@/shared/components/ProgressBar';
import { formatNumber } from '../../../../../shared/helpers/numberformats';
import {
  boxShadow,
  colorBackgroundCard, colorDarkText,
  colorText,
  colorTextAdditional,
  inboxButtonBackground, landingLightTextColor,
  socialIcons,
  tokenNameShaddow,
  tokenNameShaddow2,
  tokenText,
  tokenTextTitle
} from '../../../../../utils/palette';
import { getPresaleStatus, getPresaleStatusIcon } from './Functions';
const New = `${process.env.PUBLIC_URL}/img/launchpad/new-launchpad.png`;
import { NumericFormat } from 'react-number-format';
import { marginLeft } from '../../../../../utils/directions';

const HeaderImage = styled.div`
    background-image: url(${(props) => props.imageUrl || 'default-image-url.jpg'});
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-top: 33.33%; /* Aspect ratio of 1280x427 (3:1) */
    border-radius: 12px 12px 0 0;
    z-index: 1;
    transition: transform 0.23s ease-in-out; /* Smooth zoom effect on hover */
`;

const ItemContent = styled.div`
    transition: transform 0.23s ease-in-out; /* Smooth translate effect on hover */
`;

const CardWrapper = styled.div`
  padding: 16px;
  width: calc(100%);
  background-color: ${colorBackgroundCard};
  border-radius: 16px;
  margin-right: 2px;
  margin-bottom: 2px;
  position: sticky;
  top: 80px;
  @media (min-height: 600px) {
    top: 166px
  }
`;

const ProfileImage = styled.img`
  max-width: 50%;
  width: 128px;
  border-radius: 50%;
  border: 3px solid ${colorBackgroundCard};
`;

const Card = styled.div`
    border-radius: 16px 16px 0 0;
    font-family: Arial, sans-serif;
    overflow: hidden; /* Prevents the header image from overlapping when zoomed */
    position: relative;
    z-index: 1;
`;

const Card1 = styled.div`
    border-radius: 16px 16px 0 0;
    font-family: Arial, sans-serif;
    margin-top: 25px;
    overflow: hidden; /* Prevents the header image from overlapping when zoomed */
    position: relative;
    z-index: 1;
`;



const ProfileSection = styled.div`
    display: flex;
    align-items: center;
    margin-top: -40px; /* To bring the profile image over the header */
    background-color: ${colorBackgroundCard};
    border-top-right-radius: 400px;
    border-top-width: 1px !important;
    box-shadow: inset -0.3em 0.1em ${colorBackgroundCard};
`;


const ProfileDetails = styled.div`
    margin-left: 12px;
    flex-grow: 1;
    position: relative;
`;


const ProfileName = styled.h2`
    font-size: 1.23em;
    /*border: 1px solid red;*/
    width: calc(100% - 80px);
    margin: 0;
    color: ${tokenTextTitle};
    white-space: nowrap; /* Prevents the text from wrapping to the next line */
    overflow: hidden; /* Ensures that overflowing text is hidden */
    text-overflow: ellipsis; /* Adds the ellipsis (...) when the text overflows */
`;


const TextShaddow = styled.span`
    text-shadow: 2px 2px 2px ${tokenNameShaddow};
`;

const TextShaddow2 = styled.span`
    text-shadow: 2px 2px 2px ${tokenNameShaddow2};
`;


const ProfileTitle = styled.p`
  font-size: 12px;
  color: ${tokenText};
  margin: 2px 0;
`;


const DetailsSection = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #9A9FBF;
`;

const DetailItem = styled.p`
    margin: 5px 0;
    strong {
        color: #fff;
    }
`;
const DetailItem1 = styled.p`
    margin: 5px 0;
    strong {
        color: #fff;
    }
  text-align: center;
`;


const PresaleInfo = styled.div`
  color: ${colorTextAdditional};
  width: calc(100% - 20px);
  padding-left: 12px;
  margin-top: 25px;
  font-size: 0.97127em;
  line-height: 2em;
  text-align: left;
`;

const StatsSection = styled.div`
  background-color: #22252A;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  border-radius: 8px 8px 16px 16px;    
  box-shadow: ${boxShadow};
`;

const StatItem = styled.div`
  display: inline-block;
  width: 33%;
  text-align: center;
  p {
    margin: 5px 0;
    color: #fff;
  }
  span {
    font-size: 14px;
    color: #9A9FBF;
  }
`;
const RaisedItem = styled.div`
  display: inline-flex;
  right: 0;
  
  flex-grow: 1;
  width: 99%;
  font-size: 0.921727em;
  justify-content: flex-end;
  p:first-child {
    color: ${socialIcons};
  }
  p:last-child {
    margin-left: 5px;
    color: #9A9FBF;
  }
`;

const ProgressBarContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 7px;
  position: relative;
  width: 100%;
`;

const Funding = ({ dir, launchpadData }) => (
    <Col md={12} lg={4} xl={4}>
      <CardWrapper
          key={launchpadData.id}>
        <Card>
          {launchpadData.sub_contract !== '48' && (
              <HeaderImage imageUrl={`${process.env.PUBLIC_URL}/img/default-header.png`} />
          )}
          {launchpadData.sub_contract === '48' && (
              <HeaderImage imageUrl={`${process.env.PUBLIC_URL}/img/rich-header.png`} />
          )}
          <ItemContent>
            <ProfileSection>
              <ProfileImage src={launchpadData.logo} alt="Chef Profile" />
              <ProfileDetails>
                {launchpadData.new_launchpad && (
                    <ProductItemLabelNew src={New} alt="new" />
                )}
                <ProfileName><TextShaddow>{launchpadData.name}</TextShaddow></ProfileName>
                <ProfileTitle><TextShaddow2>{launchpadData.symbol}</TextShaddow2></ProfileTitle>
              </ProfileDetails>
            </ProfileSection>

            <DetailsSection>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <DetailItem>
                    <strong>{getPresaleStatus(launchpadData.status)}:</strong> {launchpadData.launchpadtype === 1 ? "Fair Launch" : "Presale"} {getPresaleStatusIcon(launchpadData.status)}
                  </DetailItem>
                  {launchpadData.launchpadtype === 1 && (
                      <DetailItem>
                        <strong>Soft Cap:</strong> {formatNumber(launchpadData.softcap, 8, false)} KOIN
                      </DetailItem>
                  )}
                  <DetailItem>
                  </DetailItem>
                  {launchpadData.launchpadtype === 0 && (
                      <DetailItem>
                        <strong>Soft Cap / Hard Cap:</strong> {formatNumber(launchpadData.softcap, 8, false)} / {formatNumber(launchpadData.hardcap, 8, false)} KOIN
                      </DetailItem>
                  )}
                </Col>
              </Row>
            </DetailsSection>

            {false &&
                <RaisedItem>
                  <p>Raised: </p>
                  <p>{formatNumber(Number(launchpadData.total_koin_amount)
                      .toFixed(0), 8, false)} KOIN</p>
                </RaisedItem>
            }
            <StatsSection>
              <StatItem>

                <p>${formatNumber(Number(launchpadData.volume)
                    .toFixed(0), 8, false)}</p>
                <span>Volume</span>
              </StatItem>
              <StatItem>
                <p>${formatNumber(Number(launchpadData.mc)
                    .toFixed(0), 8, false)}</p>
                <span>MC</span>
              </StatItem>
              <StatItem>
                <p>${formatNumber(Number(launchpadData.lp).toFixed(0), 8, false)}</p>
                <span>LP</span>
              </StatItem>

              <ProgressBarContainer>
                <ProgressBar
                    animated={!launchpadData.live}
                    now={launchpadData.fire ? 100 : Number(launchpadData.percentage)}
                    label={
                      launchpadData.fire && Number(launchpadData.percentage) >= 100
                          ? `🔥${launchpadData.percentage}%🔥`
                          : Number(launchpadData.percentage) > 0
                              ? `${launchpadData.percentage}%`
                              : ""
                    }
                    fire={launchpadData.fire}
                    size="big"
                />
              </ProgressBarContainer>

              <PresaleInfo>Amount</PresaleInfo>

              <Wrapper>
                <StyledNumericFormat
                    autoFocus // This will automatically focus the input on load
                    disabled={false}
                    autoComplete="off"
                    decimalScale={0}
                    allowLeadingZeros
                    allowNegative={false}
                    thousandSeparator=","
                    placeholder="Enter between 1 and 100"
                />
                <SuffixText>Koin</SuffixText> {/* Display the text after the input */}
              </Wrapper>
              <FundingsButtonWrap>
                <FundingsButton>
                  Buy
                </FundingsButton>
              </FundingsButtonWrap>
            </StatsSection>
          </ItemContent>
        </Card>

        <Card1>
            <DetailsSection>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <DetailItem1>
                    <strong>My contribution: </strong> 22 Koin
                  </DetailItem1>

                  <DetailItem1>
                    <strong>My tokens: </strong> 22 Koin
                  </DetailItem1>
                </Col>
              </Row>
            </DetailsSection>


        </Card1>
      </CardWrapper>
    </Col>
);

Funding.propTypes = {
  dir: PropTypes.string,
  launchpadData: PropTypes.object.isRequired,
};

const FundingsButtonWrap = styled.div`
  position: relative;
  height: 36px;
  border-radius: 3px;
  margin-top: 18px;
  ${marginLeft}: 0px;
  background-color: #f28705;
  margin-left: 7px;
  margin-right: 7px;

    @media screen and (max-width: 576px) {
      margin: inherit;
    }

    @media screen and (max-width: 320px) {
      margin: auto 0;
    }
  `;

const FundingsButton = styled.button`
  height: 100%;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  font-size: 1.1em;
  line-height: 1.1em;
  box-shadow: none;
  background-color: transparent;
  text-align: center!important;
  display: block;
  width: 100%;


  &:hover, &:active, &:focus:active {
    background-color: ${landingLightTextColor};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #232329;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 97%;

  /* Optional: Add styles for container if needed */
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 97%;

  /* Optional: Add styles for container if needed */
`;

const StyledNumericFormat = styled(NumericFormat)`
  line-height: 45px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 13pt;
  background-color: ${inboxButtonBackground};
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  border-radius: 8px 8px 0 0;
  width: 100%;
  color: ${colorDarkText} !important;

  /* Targeting the input inside NumericFormat */
  input {
    width: 100%;
    padding-right: 50px; /* Add padding to avoid overlap with 'Koin' text */
  }

  :focus {
    outline: none !important;
    box-shadow: 0 0 2px ${colorDarkText};
  }
`;

const SuffixText = styled.span`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colorTextAdditional};
  pointer-events: none; /* Prevent interaction with the suffix */
`;

export default Funding;

// region STYLES

const ProductItemsWrap = styled.div`
  overflow: hidden;
  width: 100%;
`;

const ProductItemsList = styled.div`
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
`;

const ProductCard = styled(Card)`
  padding: 0;
  width: 25%;

  @media screen and (max-width: 1500px) {
    width: 33.3333%;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
  }
`;

const ProductItemLabelActive = styled.img`
  position: absolute;
  width: 50px;
  z-index: 2;
  right: 32px;
  top: 18px;
`;
const ProductItemLabelCancelled = styled.img`
    position: absolute;
    width: 75px;
    z-index: 2;
    right: 32px;
    top: 18px;
`;
const ProductItemLabelFailed = styled.img`
  position: absolute;
  width: 50px;
  z-index: 2;
  right: 32px;
  top: 18px;
`;
const ProductItemLabelLaunched = styled.img`
    position: absolute;
    width: 75px;
    z-index: 2;
    right: 32px;
    top: 18px;
`;
const ProductItemLabelLaunchingSoon = styled.img`
  position: absolute;
  width: 75px;
  z-index: 2;
  right: 32px;
  top: 18px;
`;
const ProductItemLabelNew = styled.img`
    position: absolute;
    width: 50px;
    z-index: 2;
    right: 32px;
    top: 18px;
`;
const ProductItemLabelTimeout = styled.img`
  position: absolute;
  width: 66px;
  z-index: 2;
  right: 32px;
  top: 18px;
`;

const ProductItemLabelUpcoming = styled.img`
    position: absolute;
    width: 75px;
    z-index: 2;
    right: 32px;
    top: 18px;
`;


// endregion
