import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import { FormGroupField } from '@/shared/components/form/FormElements';
import {
    radioButtonsGroupLaunchType
} from '../../../Form/ReactFinalForm/CheckFormControls/components/CheckFormControlsData';

const RadioButtonGroup = ({ name, styleType }) => (
    <div>
        {radioButtonsGroupLaunchType.map(item => (
            <div className="typography--inline" key={`ts-index_${item.label}`}>
                <FormGroupField>
                    <Field
                        styles={{border: '1px solid red'}}
                        name={`${item.name}_${name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                        disabled={item.disabled}
                        styleType={styleType}
                    />
                </FormGroupField>
                <p className="subhead" style={{marginLeft: '2.21em', marginBottom: '0.5em'}}>
                    {item.description}
                </p>
            </div>
        ))}
    </div>
);

RadioButtonGroup.propTypes = {
    name: PropTypes.string,
    styleType: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
    name: '',
    styleType: '',
};

export default RadioButtonGroup;
