import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Create from '../../../Frontend/Launchpad/Create/index';
import View from '../../../Frontend/Launchpad/View/index';
import Item from '../../../Frontend/Launchpad/Item/index';
import Entry from '../../../Frontend/Launchpad/Entry/index';

export default () => (
  <Switch>
    <Route path="/launchpad/create" component={Create} />
      <Route path="/launchpad/view" component={View} />
      <Route path="/launchpad/ss" component={Entry} />
      <Route path="/launchpad/:id" component={Item} />
  </Switch>
);
