import React from 'react';
import PropTypes from 'prop-types';
import {
    textDescriptionContribution
} from '../../../Form/ReactFinalForm/CheckFormControls/components/CheckFormControlsData';

const LaunchContribution = ({ name, styleType }) => (
    <div>
        <ul className="list">
            {textDescriptionContribution.map(item => (
                <div className="typography--inline" key={`ts-index_${item.label}`}>

                    <li><p><b>{item.label}</b>: {item.description}</p></li>
                </div>
            ))}
        </ul>
    </div>
);

LaunchContribution.propTypes = {
    name: PropTypes.string,
    styleType: PropTypes.string,
};

LaunchContribution.defaultProps = {
    name: '',
    styleType: '',
};

export default LaunchContribution;
