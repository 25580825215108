import styled from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {
  colorIcon, colorRed, colorText, colorHover,
  colorGray, colorBackground, logoImg,
} from '@/utils/palette';
import {
 translate, right, left, marginRight, marginLeft,
} from '@/utils/directions';

export const TopbarContainer = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  min-height: 60px;
  z-index: 101;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  background: ${colorBackground};

    justify-content: space-between;
    align-items: center; /* Ensure proper vertical alignment */
  @media screen and (max-width: 576px) {
    min-height: 80px;
  }
`;

export const TopbarLeft = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 15px; /* Adjust as needed */
  ${left}: 0;
`;


export const TopbarTokensWrap = styled.div`
    flex-grow: 1; /* Allows it to take up available space */
    background-color: ${colorHover};
    display: flex;
    align-items: center;
    /*
    justify-content: center; /* Centers content horizontally inside 
*/

    overflow: hidden; /* Hide overflowing content for carousel effect */
    position: relative;
    height: 60px;

    @media screen and (min-width: 576px) {
        margin: auto 0 auto 15px;
    }

    @media screen and (min-width: 640px) {
        margin-right: 15px;
    }
    
    @media screen and (max-width: 576px) {
        display: none;
        visibility: hidden;
    }

    .top-label {
        font-family: Arial, sans-serif;
        background-color: #FFD700;
        padding: 15px 20px;
        border-radius: 0 30px 30px 0;
        font-weight: 750;
        display: flex;
        align-items: center;
        margin-right: 15px;
        flex-shrink: 0; /* Ensures the label does not shrink */
        z-index: 1; /* Keeps the label above the scrolling tokens */
    }

    .tokens-carousel {
        display: flex;
        align-items: center;
        white-space: nowrap;
        animation: scroll 47s linear infinite; /* Animate continuously */
        position: absolute;
        left: calc(100px + 15px); /* Position it next to the label */
    }
    .tokens-carousel:hover {
        animation-play-state: paused; /* Pause the scroll animation */
    }

    /* Duplicate the token items to create an endless effect */

    .tokens-carousel .token-item {
        display: inline-flex;
        align-items: center;
        background-color: ${colorBackground};
        color: ${colorText};
        border-radius: 50px;
        padding: 5px 15px;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: bold;
    }
    .tokens-carousel .token-item:hover {
        cursor: pointer;
    }

    .tokens-carousel .token-item.one {
        border: 1px groove rgba(7, 234, 64, 0.6);
    }

    .tokens-carousel .token-item.two {
        border: 1px groove rgba(7, 98, 234, 0.6);
    }

    .tokens-carousel .token-item.three {
        border: 1px groove rgba(204, 7, 234, 0.6);
    }

    .tokens-carousel .token-item img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: 2px;
        margin-right: 5px;
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-50%); /* Scroll until halfway (duplicates create the loop) */
        }
    }
`;

export const TopbarRight = styled.div`
  display: flex;
  align-items: center;
 /* padding-right: 15px;*/
  justify-content: flex-end;
    ${right}: 0;
    ${marginRight}: 15px;

  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
  }

  @media screen and (min-width: 576px) {
    min-height: auto;
    flex-wrap: nowrap;
  }
`;
export const TopbarRightOver = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
`;

export const TopbarLogo = styled(Link)`
  width: 150px;
  height: 32px;
  margin: auto 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  display: none;
  background-image: ${logoImg};

  @media screen and (min-width: 835px) {
    display: block;
  }
`;


export const TopbarSearchWrap = styled.div`
    margin: 0;
    display: flex;
    height: 60px;

    @media screen and (min-width: 576px) {
        margin: auto 0 auto 15px;
    }

    @media screen and (min-width: 640px) {
        margin-right: 15px;
    }
`;


export const TopbarButton = styled.button`
  font-size: 18px;
  height: 100%;
  min-width: 60px;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  color: ${colorText};

  &:hover {
    background-color: ${colorHover};
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: ${lighten(0.25, colorGray)};
  }

  @media screen and (max-width: 640px) {
    padding: 0 5px;
  }

  @keyframes beforePulse {

    from {
      width: 7px;
      height: 7px;
    }

    25% {
      width: 13px;
      height: 13px;
    }

    to {
      width: 7px;
      height: 7px;
    }
  }
`;

export const TopbarButtonNewLabel = styled.span`
  position: absolute;
  display: block;
  top: 20px;
  ${right}: 9px;

  & > span {
    position: relative;
    display: block;

    &:before {
      background-color: rgba(224, 83, 111, 0.2);
      content: "";
      position: absolute;
      top: 50%;
      border-radius: 50%;
      animation: beforePulse 1.5s infinite;
      ${left}: 50%;
      transform: ${translate};
    }

    &:after {
      height: 7px;
      width: 7px;
      background-color: ${colorRed};
      content: "";
      position: absolute;
      top: 50%;
      border-radius: 50%;
      ${left}: 50%;
      transform: ${translate};
    }
  }

  @media screen and (max-width: 576px) {
    top: 10px;
  }
`;

export const TopbarBack = styled.button`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
`;

export const TopbarLinkIcon = styled.span`
  font-size: 13px;
  line-height: 13px;
  ${marginRight}: 10px;
  color: ${colorIcon};
`;

export const TopbarLinkTitle = styled.p`
  display: flex;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
`;

export const TopbarDownIcon = styled(DownIcon)`
  height: 18px;
  margin: auto 0;
  fill: ${lighten(0.25, colorGray)};
  ${marginLeft}: 8px;
`;
