import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarLanguage from '../components/topbar/TopbarLanguage';
import TopbarMail from '../components/topbar/TopbarMail';
import TopbarProfile from '../components/topbar/TopbarProfile';
import TopbarNotification from '../components/topbar/TopbarNotification';
import TopbarSearch from '../components/topbar/TopbarSearch';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarSearchWrap, TopbarTokensWrap,
} from '../components/topbar/BasicTopbarComponents';
import TopbarWallet from '../components/topbar/TopbarWallet';
import axios from 'axios';
import { Contract, Provider, Signer } from 'koilib';
import {
  kapInfoMainnetContract,
  mainnetPresalemapContract,
  mainnetProviderUrl
} from '../../../shared/constants/koincity-constants';
import presaleMap from '../../../shared/components/wallet/ABI/presalemap-abi.json';

// Fetch the top tokens
const getTopTokens = async () => {
  const response = await axios.get(process.env.REACT_APP_DOMAIN + "/api/kct_top_tokens", {
    params: {},
    headers: {
      "Content-Type": "application/json"
    }
  });
  if (response.data.success) {
    return response.data.rows;
  } else {
    return [];
  }
}

// Fetch additional token data
async function getTokenData(tokens) {
    const provider = new Provider([mainnetProviderUrl]);
    const koincityContract = mainnetPresalemapContract;
    const signer = Signer.fromSeed("koincity");
    signer.provider = provider;
    const Abi = {
        koilib_types: presaleMap.types,
        ...presaleMap
    }
    const contract = new Contract({
        id: koincityContract,
        abi: Abi,
        provider,
        signer,
    });

    const contractFunctions = contract.functions;

    const result = await Promise.all(
        tokens.map(async (token, index) => { // Use the index here
            const presalePromise = contractFunctions.getPresales({
                start: token.launchpad_number,
                limit: 1,
                direction: 1,
            });

            const metadataPromise = contractFunctions.getMetadata({
                value: token.launchpad_number,
            });

            const dexPairPromise = contractFunctions.getKoinDxPair({
                value: token.launchpad_number,
            });


            const [presaleResponse, metadataResponse, dexPairResponse] = await Promise.all([presalePromise, metadataPromise, dexPairPromise]);

            const presale = presaleResponse.result.value[0];
            const metadata = metadataResponse.result;
            const dexPair = dexPairResponse.result;
            const url = !!dexPair ? "/markets/" + dexPair.value : "/launchpad/" + token.launchpad_number

            return {
                position: index + 1, // Use the index to ensure the correct order
                link: presale?.subcontract_id,
                url: url,
                name: presale?.token_params?.name,
                symbol: presale?.token_params?.symbol,
                logo: metadata?.logo,
            };
        })
    );
    return result.concat(result).concat(result).concat(result);
}

// Topbar component
const Topbar = ({
                  changeMobileSidebarVisibility,
                  changeSidebarVisibility,
                }) => {
    const [tokenData, setTokenData] = useState([]);
    const [translateX, setTranslateX] = useState(0); // To track the current translateX value
    const [counter, setCounter] = useState(0); // Counter to track the number of iterations
    const carouselRef = useRef(null); // Ref for the carousel container
    const history = useHistory(); // React Router v5 hook for navigation

  // Fetch and prepare token data
  async function prepareTopTokens() {
    const tokens = await getTopTokens();
    if (tokens.length > 0) {
      const data = await getTokenData(tokens);
      setTokenData(data); // Update state with token data
    }
  }

    useEffect(() => {
        prepareTopTokens();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const carousel = carouselRef.current;
            if (carousel && tokenData.length > 0) {
                const firstItem = carousel.children[0]; // Get the current first token item
                const itemWidth = firstItem.offsetWidth; // Include margin-right of 10px

                // Update the translateX value to move the remaining tokens to the left by the current item width
                setTranslateX((prevTranslateX) => {
                    const newTranslateX = prevTranslateX + itemWidth + 10;

                    // Apply the translation to move the carousel to the left
                    carousel.style.transform = `translateX(-${newTranslateX}px)`;
                    carousel.style.transition = `transform 0.5s ease-in-out`; // Smooth transition
                    //carousel.style.left = '310px';

                    return newTranslateX;
                });

                // Increment the counter for each iteration
                setCounter((prevCounter) => prevCounter + 1);

                // Optional: You can also log the counter or display it in the UI

                // Remove the first item from the list after the translation
                setTimeout(() => {
                    setTokenData((prevTokenData) => {
                        const newTokenData = [...prevTokenData];
                        // Uncomment this if you want to remove the first item
                        // newTokenData.shift();
                        return newTokenData;
                    });
                }, 500); // Wait for the translation to complete before removing the item
            }
        }, 3000); // Wait 3 seconds between transitions

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [tokenData, translateX, counter]);

  return (
      <TopbarContainer>
        <TopbarLeft>
          <TopbarSidebarButton
              onClickMobile={changeMobileSidebarVisibility}
              onClickDesktop={changeSidebarVisibility}
          />
          <TopbarLogo to="/online_marketing_dashboard" />
        </TopbarLeft>
          <TopbarTokensWrap>
              <div
                  onClick={() => history.push("./ss")}
                  className="top-label">
                  🏆 Top
              </div>
              <div className="tokens-carousel" ref={carouselRef}>
                  {tokenData.map((token, index) => (
                      <div
                          className={token.position === 1 ? 'token-item one' : token.position === 2 ? 'token-item two' : token.position === 3 ? 'token-item three' : 'token-item'}
                          key={index}
                          onClick={() => history.push(token.url)} // Navigate to token.url on click
                          style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickability
                      >
                          #{token.position}
                          <img src={token.logo} alt={token.name}/>
                          {token.symbol}
                      </div>
                  ))}
              </div>
          </TopbarTokensWrap>
          <TopbarRight>
              {false &&
                  <TopbarSearchWrap>
                      <TopbarSearch/>
                  </TopbarSearchWrap>
              }
              <TopbarRightOver>
                  {false &&
                      <TopbarNotification/>
                  }
                  {false &&
                      <TopbarMail new/>
                  }
                  <TopbarProfile/>
                  <TopbarWallet/>
              </TopbarRightOver>
          </TopbarRight>
      </TopbarContainer>
  );
};

Topbar.propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
