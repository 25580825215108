import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {Col, Row} from 'react-bootstrap';
import FormField from '@/shared/components/form/FormField';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import LaunchType from "./Step2-launch-type"
import LaunchContribution from "./Step2-launch-contribution"
import DatePicker from "./Step2-datepicker"
import {FormGroupIcon} from "../../../../shared/components/form/FormElements";

const VerticalForm = ({ onSubmit }) => {
  const { t } = useTranslation('common');
  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
      <Row>
        <Col md={12}>
          <h1 className="page-title">📅 Step 2: Launchpad Details</h1>
          <h3 className="page-subhead subhead">
            Choose the start and end dates for your token’s presale. These dates define the window during which
            investors can participate in your presale. Planning your timeline effectively can impact the overall
            success of your launch.
          </h3>
        </Col>
      </Row>
        <CardBody>
          <Form onSubmit={onSubmit} validate={validate}>
            {({
              handleSubmit,
              form: { reset },
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormGroup>
                    <DatePicker/>
                </FormGroup>
                <FormGroup>
                  <CardTitleWrap>
                    <CardTitle>Define Your Financial Goals</CardTitle>
                    <CardSubhead>
                      Set clear, attainable financial thresholds that align with your project’s objectives and market
                      expectations.
                    </CardSubhead>
                  </CardTitleWrap>
                  <LaunchType/>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Softcap</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="softcap"
                        component={FormField}
                        type="number"
                        placeholder="EX: 2,500"
                    />
                    <FormGroupIcon>
                      <p>
                        KOIN
                      </p>
                    </FormGroupIcon>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Hardcap</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="hardcap"
                        component={FormField}
                        type="number"
                        placeholder="EX: 5,000"
                    />
                    <FormGroupIcon>
                      <p>
                        KOIN
                      </p>
                    </FormGroupIcon>
                  </FormGroupField>
                </FormGroup>


                <FormGroup style={{marginTop: '25px'}}>
                  <CardTitleWrap>
                    <CardTitle>Set Contribution Limits</CardTitle>
                    <CardSubhead>
                      Determine the range of allowable contributions to ensure equitable participation and prevent market manipulation by large stakeholders.
                    </CardSubhead>
                  </CardTitleWrap>
                  <LaunchContribution/>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Minimum</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="minContribution"
                        component={FormField}
                        type="number"
                        placeholder="EX: 1"
                    />
                    <FormGroupIcon>
                      <p>
                        KOIN
                      </p>
                    </FormGroupIcon>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Maximum</FormGroupLabel>
                  <FormGroupField>
                    <Field
                        name="maxContribution"
                        component={FormField}
                        type="number"
                        placeholder="EX: 1,000"
                    />
                    <FormGroupIcon>
                      <p>
                        KOIN
                      </p>
                    </FormGroupIcon>
                  </FormGroupField>
                </FormGroup>


                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Step 3</Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};
http://localhost:3000/easydev/booking_dashboard
VerticalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default VerticalForm;
