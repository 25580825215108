import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@/shared/components/ConnectModal';

const ConnectModal = () => {
  const { t } = useTranslation('common');

  return (
      <Modal
          color="primary"
          title="Select Your Wallet"
          btn="Connect Wallet"
          message1="With connecting your wallet you accept the Terms and conditions"
      />
  );
};

export default ConnectModal;


