import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
    boxShadow,
    colorBackgroundBody,
    colorBackgroundCard,
    colorFolderHover,
    colorHover, colorIcon,
    colorText,
    imgInvert,
    inboxButtonBackgroundHover,
    socialIcons,
    tokenNameShaddow,
    tokenNameShaddow2,
    tokenText,
    tokenTextTitle
} from '../../../../../utils/palette';
import ProgressBar from '@/shared/components/ProgressBar';
import { formatNumber } from '../../../../../shared/helpers/numberformats';
import { getPresaleStatus, getPresaleStatusIcon } from '../../Item/components/Functions';

const Sale = `${process.env.PUBLIC_URL}/img/for_store/catalog/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/img/for_store/catalog/new_lable.png`;

const HeaderImage = styled.div`
    background-image: url(${(props) => props.imageUrl || 'default-image-url.jpg'});
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-top: 33.33%; /* Aspect ratio of 1280x427 (3:1) */
    border-radius: 12px 12px 0 0;
    z-index: 1;
    transition: transform 0.23s ease-in-out; /* Smooth zoom effect on hover */
`;

const ItemContent = styled.div`
    transition: transform 0.23s ease-in-out; /* Smooth translate effect on hover */
`;

const CardWrapper = styled.div`
    padding: 16px;
    width: calc(25% - 1em);
    background-color: ${colorBackgroundCard};
    border-radius: 16px;
    margin-right: 2px;
    margin-bottom: 2px;

    @media (max-width: 1536px) {
        width: calc(33% - 1em);
    }
    @media (max-width: 1228px) {
        width: calc(50% - 1em);
    }
    @media (max-width: 896px) {
        width: calc(100% - 1em);
    }
`;

const ProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid ${colorBackgroundCard};
`;

const Card = styled.div`
    border-radius: 16px 16px 0 0;
    font-family: Arial, sans-serif;
    overflow: hidden; /* Prevents the header image from overlapping when zoomed */
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-in-out; /* Smooth transition for all card hover effects */

    &:hover {
        cursor: pointer;
    }

    &:hover ${HeaderImage} {
        transform: scale(1.040); /* Zoom the header image to 102.5% */
    }

    &:hover ${ItemContent} {
        transform: translateY(-5px); /* Move the content up slightly */
    }
`;



const ProfileSection = styled.div`
    display: flex;
    align-items: center;
    margin-top: -40px; /* To bring the profile image over the header */
    background-color: ${colorBackgroundCard};
    border-top-right-radius: 400px;
    border-top-width: 1px !important;
    box-shadow: inset -0.3em 0.1em ${colorBackgroundCard};
`;


const ProfileDetails = styled.div`
    margin-left: 12px;
    flex-grow: 1;
    position: relative;
`;

const ProfileName = styled.h2`
    font-size: 1.23em;
    /*border: 1px solid red;*/
    width: calc(100% - 80px);
    margin: 0;
    color: ${tokenTextTitle};
    white-space: nowrap; /* Prevents the text from wrapping to the next line */
    overflow: hidden; /* Ensures that overflowing text is hidden */
    text-overflow: ellipsis; /* Adds the ellipsis (...) when the text overflows */
`;

const TextShaddow = styled.span`
    text-shadow: 2px 2px 2px ${tokenNameShaddow};
`;

const TextShaddow2 = styled.span`
    text-shadow: 2px 2px 2px ${tokenNameShaddow2};
`;


const ProfileTitle = styled.p`
  font-size: 12px;
  color: ${tokenText};
  margin: 2px 0;
`;

const CustomTag = styled.div`
  background-color: #006D64;
  color: white;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  margin-left: auto;
`;

const DetailsSection = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #9A9FBF;
`;

const DetailItem = styled.p`
    margin: 5px 0;
    strong {
        color: #fff;
    }
`;

const DetailDescription = styled.p`
    margin: 15px 0 17px;
    font-size: 1em;
    height: 65px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit the text to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.6em; /* Adjust this to control line spacing */
    max-height: calc(1.61em * 2); /* Ensure the height doesn't exceed 2 lines */
    strong {
        color: #fff;
    }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  color: ${socialIcons};
  img {
    width: 22px;
    cursor: pointer;
  }
    a, span{
        color: ${colorText};
        margin: 0;
        margin-top: -3px;
        padding: 0;
        height: 16px;
    }
    a:hover{
        color: ${colorIcon};        
    }
`;

const StatsSection = styled.div`
  background-color: #22252A;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  border-radius: 8px 8px 16px 16px;    
  box-shadow: ${boxShadow};
`;

const StatItem = styled.div`
  display: inline-block;
  width: 33%;
  text-align: center;
  p {
    margin: 5px 0;
    color: #fff;
  }
  span {
    font-size: 14px;
    color: #9A9FBF;
  }
`;

const ProgressBarContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 7px;
  position: relative;
  width: 100%;
`;

const Progress = styled.div`
  background-color: ${(props) => props.color || '#FF4D4D'};
  height: 100%;
  width: ${(props) => props.width || '0%'};
  border-radius: 5px;
`;

const ProgressText = styled.p`
  font-size: 12px;
  color: #9A9FBF;
  text-align: right;
`;

const ProductItems2 = ({ items }) => {
    const history = useHistory(); // Use the hook to get the history object

    return (
        <ProductItemsWrap>
            <ProductItemsList>
                {items.map(item => (
                    <CardWrapper
                        onClick={() => history.push('./' + item.sub_contract)} // Use the history object here
                        key={item.id}>
                        <Card>
                            {item.sub_contract !== 48 && (
                                <HeaderImage imageUrl={`${process.env.PUBLIC_URL}/img/default-header.png`} />
                            )}
                            {item.sub_contract === 48 && (
                                <HeaderImage imageUrl={`${process.env.PUBLIC_URL}/img/rich-header.png`} />
                            )}
                            <ItemContent>
                                <ProfileSection>
                                    <ProfileImage src={item.logo} alt="Chef Profile" />
                                    <ProfileDetails>
                                        {item.new_launchpad && (
                                            <ProductItemLabel src={New} alt="new" />
                                        )}
                                        <ProfileName><TextShaddow>{item.name}</TextShaddow></ProfileName>
                                        <ProfileTitle><TextShaddow2>{item.symbol}</TextShaddow2></ProfileTitle>
                                    </ProfileDetails>
                                </ProfileSection>

                                <DetailsSection>
                                    <DetailItem>
                                        <strong>{getPresaleStatus(item.status)}:</strong> {item.launchpadtype === 1 ? "Fair Launch" : "Presale"} {getPresaleStatusIcon(item.status)}
                                    </DetailItem>
                                    {item.launchpadtype === 1 && (
                                        <DetailItem>
                                            <strong>SC:</strong> {formatNumber(item.softcap, 8, false)} KOIN
                                        </DetailItem>
                                    )}
                                    {item.launchpadtype === 0 && (
                                        <DetailItem>
                                            <strong>SC / HC:</strong> {formatNumber(item.softcap, 8, false)} / {formatNumber(item.hardcap, 8, false)} KOIN
                                        </DetailItem>
                                    )}
                                    <DetailDescription>
                                        {item.description}
                                    </DetailDescription>
                                </DetailsSection>

                                <SocialIcons>
                                    {item.website.length > 0 &&
                                        <a target={"_blank"} href={item.website} onClick={(e) => e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faGlobe}/>
                                        </a>
                                    }
                                    {item.website.length == 0 &&
                                        <span>&nbsp;</span>
                                    }
                                    {item.twitter.length > 0 &&
                                        <a target={"_blank"} href={item.twitter} onClick={(e) => e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faXTwitter}/>
                                        </a>
                                    }
                                    {item.twitter.length == 0 &&
                                        <span>&nbsp;</span>
                                    }
                                    {item.telegram.length > 0 &&
                                        <a target={"_blank"} href={item.telegram} onClick={(e) => e.stopPropagation()}>
                                            <FontAwesomeIcon icon={faTelegramPlane}/>
                                        </a>
                                    }
                                    {item.telegram.length == 0 &&
                                        <span>&nbsp;</span>
                                    }
                                </SocialIcons>

                                <StatsSection>
                                    <StatItem>
                                        <p>${formatNumber(Number(item.volume)
                                            .toFixed(0), 8, false)}</p>
                                        <span>Volume</span>
                                    </StatItem>
                                    <StatItem>
                                        <p>${formatNumber(Number(item.mc).toFixed(0), 8, false)}</p>
                                        <span>MC</span>
                                    </StatItem>
                                    <StatItem>
                                        <p>${formatNumber(Number(item.lp).toFixed(0), 8, false)}</p>
                                        <span>LP</span>
                                    </StatItem>

                                    <ProgressBarContainer>
                                        <ProgressBar
                                            animated={!item.live}
                                            now={item.fire ? 100 : Number(item.percentage)}
                                            label={
                                                item.fire && Number(item.percentage) >= 100
                                                    ? `🔥${item.percentage}%🔥`
                                                    : Number(item.percentage) > 0
                                                        ? `${item.percentage}%`
                                                        : ""
                                            }
                                            fire={item.fire}
                                            size="big"
                                        />
                                    </ProgressBarContainer>
                                </StatsSection>
                            </ItemContent>
                        </Card>
                    </CardWrapper>
                ))}
            </ProductItemsList>
        </ProductItemsWrap>
    );
};

ProductItems2.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    description: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string),
    new: PropTypes.bool,
  })),
};

ProductItems2.defaultProps = {
  items: [],
};

export default ProductItems2;

// region STYLES

const ProductItemsWrap = styled.div`
  overflow: hidden;
  width: 100%;
`;

const ProductItemsList = styled.div`
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
`;

const ProductCard = styled(Card)`
  padding: 0;
  width: 25%;

  @media screen and (max-width: 1500px) {
    width: 33.3333%;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
  }
`;

const ProductItemLabel = styled.img`
    position: absolute;
    width: 50px;
    z-index: 2;
    /*
    right: 60px;
    top: 1px;
    
     */
    right: 32px;
    top: 18px;
`;


// endregion

