import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { marginLeft, marginRight, paddingLeft } from '../../../../utils/directions';
import { landingLightTextColor } from '../../../../utils/palette';
import ExitRunIcon from 'mdi-react/ExitRunIcon';
import store from '../../../../redux/store/store';


const disconnectWallets = () => {

    localStorage.removeItem('connectedAddress');
    localStorage.removeItem('connectedAddressFormat');
    localStorage.removeItem('connectedMKW');
    localStorage.removeItem('connectedKondor');
    localStorage.removeItem('connectedWC');
    localStorage.removeItem('usernameValue');
    localStorage.removeItem('kapDomainUsername');
    localStorage.removeItem('kapDomain');
    localStorage.removeItem('nicknameUsername');
    localStorage.removeItem('nickname');
    localStorage.removeItem('connectedId');
    localStorage.removeItem('signedMessage');
    localStorage.removeItem('kondorAccounts');
    localStorage.removeItem('avatar');
    store.dispatch({
        type: "USER",
        payload: { username: "" }
    });
    store.dispatch({
        type: "SIGNER",
        payload: { signer: null }
    });
    store.dispatch({
        type: "KONDOR_ACCOUNTS",
        payload: { kondorAccounts: [] }
    });
    store.dispatch({
        type: "WALLET_CONNECT_ACCOUNTS",
        payload: { kondorAccounts: [] }
    });
}


const DisconnectButton = () => {
  const { t } = useTranslation('common');



  return (
      <div>
          <TopbarWalletWrap>
              <TopbarWalletConnectButton type="button" onClick={() => disconnectWallets()}>
                  <ExitRunIcon />
                  <TopbarWalletConnect>
                      Disconnect
                  </TopbarWalletConnect>
              </TopbarWalletConnectButton>
          </TopbarWalletWrap>
      </div>
  );
};

const TopbarWalletWrap = styled.div`
  position: relative;
  margin-bottom: 0;
  height: 27px;
  border-radius: 3px;
  margin-top: 18px;
  ${marginLeft}: 0px;
  background-color: #f28705;

  @media screen and (max-width: 576px) {
    margin: inherit;
  }

  @media screen and (max-width: 320px) {
    margin: auto 0;
  }
`;



const TopbarWalletConnectButton = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  background-color: transparent;

  &:hover, &:active, &:focus:active {
    background-color: ${landingLightTextColor};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #232329;
  }
`;




const TopbarWalletConnect = styled.p`
  margin: auto 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  display: flex;
    ${marginLeft}: 5px;
    ${marginRight}: 5px;
  color: #232329;
  

  @media screen and (min-width: 480px) {
      display: block;
  }
`;

export default DisconnectButton;


