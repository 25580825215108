import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import { FormGroupField } from '@/shared/components/form/FormElements';
import { radioButtonsGroupKoincityPackage1 } from '../../../Form/ReactFinalForm/CheckFormControls/components/CheckFormControlsData';
import {Card} from "react-bootstrap";
import {CardBody, CardSubhead, CardTitle, CardTitleWrap} from "../../../../shared/components/Card";
import styles from "./Create.module.css";

const RadioButtonGroup = ({ name, styleType }) => (
    <div key={"rbg1"}>
        {radioButtonsGroupKoincityPackage1.map(item => (
            <FormGroupField key={`pg_index_${item.label}`}>
                <Field
                    name={`${item.name}_${name}`}
                    component={renderRadioButtonField}
                    label={item.label}
                    radioValue={item.radioValue}
                    initialValue={item.initialValue}
                    disabled={item.disabled}
                    styleType={styleType}
                />
            </FormGroupField>
        ))}
        <Card className={styles.margin}>
            <CardBody>
                <CardSubhead>
                    <div className={styles.flex}>
                        <div className={styles.leftItem}>Create Launchpad:</div>
                        <div className={styles.rightItemRed}>50 Koin</div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div className={styles.leftItem}>Create Token:</div>
                        <div className={styles.rightItemGreen}>0 Koin</div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div className={styles.leftItem}>Sold Token:</div>
                        <div className={styles.rightItemGreen}>2 %</div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div className={styles.leftItem}>Sold Koin:</div>
                        <div className={styles.rightItemGreen}>2 %</div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div className={styles.leftItem}>Finalize Launchpad:</div>
                        <div className={styles.rightItemGreen}>0 Koin</div>
                    </div>
                </CardSubhead>
            </CardBody>
        </Card>
    </div>
);

RadioButtonGroup.propTypes = {
    name: PropTypes.string,
    styleType: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
    name: '',
    styleType: '',
};

export default RadioButtonGroup;
