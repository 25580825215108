import { useState, useCallback } from 'react';
import { Methods, WebWalletConnectKoinos } from '@armana/walletconnect-koinos-sdk-js';
import store from '../../../../redux/store/store';
import { getUserData } from './useHelpFunctions';

const projectId = '13b5e9d8058df8d11e09f7af8daf9681';
let walletConnectKoinos = null;

walletConnectKoinos = new WebWalletConnectKoinos(
    {
        projectId,
        metadata: {
            name: 'Koincity',
            description: 'Launch your Koinos Project with Koincity.',
            url: 'https://koincity.com',
            icons: ['https://koincity.com/logo512.png'],
        },
        modalOptions: {
            explorerRecommendedWalletIds: [
                'de9e8421961e35c5e35cb63e3cd7be9af328a62c7b5a11f95ca116bf128a7424',
                '4e21a70acc8f11aa35f87733de2fbada29a2dd08e9011d34d92522fb8ad0e3d2',
            ],
            themeMode: 'dark',
            themeVariables: {
                '--w3m-color-mix': '#00BB7F',
                '--w3m-color-mix-strength': 40,
                '--w3m-accent-color': '#e86a33',
                '--w3m-color-overlay': 'rgba(232, 106, 51, 0.1)',
            },
        },
    },
);

async function connectWalletConnect(setConnectedState) {
    let myAddress = '';
    try {
        const chainIdMainnet = 'koinos:EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3ejxRSewi7qIBfSA=='; // mainnet
        const accounts = await walletConnectKoinos.connect(
            [chainIdMainnet],
            [
                Methods.SignAndSendTransaction,
                Methods.PrepareTransaction,
                Methods.WaitForTransaction,
            ],
        );
        if (accounts.length > 0) {
            let address = accounts[0];
            myAddress = address;
            address = address.slice(0, 3) + '...' + address.slice(-3);
            getUserData(accounts[0]);
            const signer = await walletConnectKoinos.getSigner(accounts[0]);
            store.dispatch({
                type: 'SIGNER',
                payload: { signer },
            });
            store.dispatch({
                type: "KONDOR_ACCOUNTS",
                payload: { kondorAccounts: [] },
            });
            store.dispatch({
                type: "WALLET_CONNECT_ACCOUNTS",
                payload: { walletConnectAccounts: accounts },
            });

            setConnectedState({
                connectedKondor: false,
                connectedMKW: false,
                connectedWC: true,
                connectedAddress: accounts[0],
                connectedName: address,
                connectedAccount: accounts[0],
                connectedAddressFormat: address,
                kondorAccounts: null,
                walletConnectAccounts: accounts,
            });

            await localStorage.setItem('connectedAddress', accounts[0]);
            await localStorage.setItem('connectedAddressFormat', address);
            await localStorage.setItem('connectedKondor', false);
            await localStorage.setItem('kondorAccounts', null);
            await localStorage.setItem('walletConnectAccounts', JSON.stringify(accounts));
            await localStorage.setItem('connectedMKW', false);
            await localStorage.setItem('connectedWC', true);
            await localStorage.setItem('walletConnectKoinos', walletConnectKoinos);
        }
    } catch (e) {
        console.log(e);
    }
}

const useWalletConnectModal = () => {
    const [connectedState, setConnectedState] = useState({
        connectedKondor: false,
        connectedMKW: false,
        connectedWC: false,
        connectedAddress: '',
        connectedName: '',
        connectedAccount: '',
        connectedAddressFormat: '',
        kondorAccounts: null,
        walletConnectAccounts: null,
    });
    const connectWC = useCallback(async () => await connectWalletConnect(setConnectedState), []);

    return { connectWC, connectedState };
};

export default useWalletConnectModal;
