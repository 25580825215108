export const mainnetProviderUrl = "https://koinos-api.koincity.com";
export const harbingerProviderUrl = "https://harbinger-api.koinos.io";

export const kapInfoMainnetContract = "1EttfMuvTXGh8oE6vLiRF5JfqBvRiofFkB";
export const mainnetNicknamesContract = "1KD9Es7LBBjA1FY3ViCgQJ7e6WH1ipKbhz";
export const mainnetPresalemapContract = "1FZnS98QunDkzVjMz4G8BRSTAZyYCaPHhm";



export const tokenlockmapContract = "1FT1D2jLKeeEFcfG5Zo7WKfYNHH9MMeK5N"
