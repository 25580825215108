import { createStore } from 'redux';


const defaultState = {
    tokenAddress: '0x1',
    username: '',
    metadata: null,
    signer: null,
    showConnectWallet: 'false',
    pair: process.env.REACT_APP_PAIR,
    showAccount: 'false',
    mode: 0,
    item: 3,
    pair_token_a: 'koin',
    symbol: '/broken-logo.png',
    pair_token_b: process.env.REACT_APP_PAIR_TOKEN_B,
    valueUSD: 0,
    kondorAccounts: [],
    walletConnectAccounts: [],

};
function chatStore(state = defaultState, action) {
    switch (action.type) {
        case 'SELECT':
            return {
 ...state,
                tokenAddress: action.payload.tokenAddress,
            };
        case 'USER':
            return {
 ...state,
                username: action.payload.username,
            };
        case 'WALLET':
            return {
 ...state,
                showConnectWallet: action.payload.showConnectWallet,
            };
        case 'ACCOUNT':
            return {
 ...state,
                showAccount: action.payload.showAccount,
            };
        case 'ID':
            return {
 ...state,
                id: action.payload.id,
            };
        case 'METADATA':
            return {
 ...state,
                metadata: action.payload.metadata,
            };
        case 'SIGNER':
            return {
 ...state,
                signer: action.payload.signer,
            };
        case 'MARKETS_MODE':
            return {
 ...state,
                mode: action.payload.mode,
            };
        case 'MARKETS_PAIR':
            return {
 ...state,
                pair: action.payload.pair,
            };
        case 'MOBILE_ITEM':
            return {
 ...state,
                item: action.payload.item,
            };
        case 'PAIR_TOKEN_A':
            return {
 ...state,
                pair_token_a: action.payload.pair_token_a,
            };
        case 'PAIR_TOKEN_B':
            return {
 ...state,
                pair_token_b: action.payload.pair_token_b,
            };
        case 'VALUE_USD':
            return {
 ...state,
                valueUSD: action.payload.valueUSD,
            };
        case 'SYMBOL':
            return {
 ...state,
                symbol: action.payload.symbol,
            };
        case 'KONDOR_ACCOUNTS':
            return {
                ...state,
                symbol: action.payload.kondorAccounts,
            };
        case 'WALLET_CONNECT_ACCOUNTS':
            return {
...state,
                symbol: action.payload.walletConnectAccounts,
            };
        default:
            return state;
    }
}
export default createStore(chatStore);
