import React, { Component } from "react";
import "./KapInfoChat.css";
import {Contract, Provider, Signer} from "koilib";
import {kapInfoMainnetContract, kapInfoTestnetContract, normalizeIpfsUris} from "./kap.ts";
import kapinfoAbi from "@/shared/components/wallet/ABI/kapinfo-abi.json";
import nftAbi from "@/shared/components/wallet/ABI/nft-abi.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope, faGlobe} from '@fortawesome/free-solid-svg-icons';
import { faBitcoin, faDiscord, faEthereum, faGithub, faReddit, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Avatar from './avatar'
import {harbingerProviderUrl, mainnetProviderUrl} from "@/shared/constants/koincity-constants";


class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: null,
            links: null,
            avatar: null,
            avatarDefault: false,
            bio: null,
            name: null,
            loading: true,
            noActiveKapAccount: false
        }
    }
    isMainnet(){
        return !this.props.testnet;
    }



    async componentDidMount(){
        let provider = null
        let kapContract = null
        if(this.isMainnet()) {
            provider = new Provider([mainnetProviderUrl]);
            kapContract = kapInfoMainnetContract
        } else {
            provider = await new Provider([harbingerProviderUrl]);
            kapContract = kapInfoTestnetContract
        }


        const signer = Signer.fromSeed("my seed");
        signer.provider = provider;

        const Abi = {
            koilib_types: kapinfoAbi.types,
            ...kapinfoAbi
        }

        const koinContract = new Contract({
            id: kapContract,
            abi: Abi,
            provider,
            signer,
        });

        const koin = koinContract.functions;

        const result = (await koin.get_profile({
            address: this.props.owner
        })).result;

        await this.setState({
            result: result
        })

        if(!!result){
            this.prepareView(result)
        } else {
            await this.setState({
                noActiveKapAccount: true,
                loading: false
            })
        }
    }


    async prepareView(data){
        let links = null
        let avatar = null
        let avatarDefault = false
        let bio = null
        let name = null
        if(!!data && !!data['links']) {
            links = []
            let data_link = data['links']
            let link_btc = data_link.find(obj => obj.key.toLowerCase() === 'btc');
            let link_discord = data_link.find(obj => obj.key.toLowerCase() === 'discord');
            let link_email = data_link.find(obj => obj.key.toLowerCase() === 'email');
            let link_eth = data_link.find(obj => obj.key.toLowerCase() === 'eth');
            let link_github = data_link.find(obj => obj.key.toLowerCase() === 'github');
            let link_reddit = data_link.find(obj => obj.key.toLowerCase() === 'reddit');
            let link_telegram = data_link.find(obj => obj.key.toLowerCase() === 'telegram');
            let link_twitter = data_link.find(obj => obj.key.toLowerCase() === 'twitter');
            let link_website = data_link.find(obj => obj.key.toLowerCase() === 'website');

            if(link_btc){ links.btc = link_btc.value }
            if(link_discord){ links.discord = link_discord.value }
            if(link_email){ links.email = link_email.value }
            if(link_eth){ links.eth = link_eth.value }
            if(link_github){ links.github = link_github.value }
            if(link_reddit){ links.reddit = link_reddit.value }
            if(link_telegram){ links.telegram = link_telegram.value }
            if(link_twitter){ links.twitter = link_twitter.value }
            if(link_website){
                let website = link_website.value
                if (website.startsWith('http://') || website.startsWith('https://')) {
                    links.website = website;
                } else {
                    links.website = 'https://' + website;
                }
            }
        }

        if(!!data && !!data['bio']) {
            bio = data['bio']
        }
        if(!!data && !!data['name']) {
            name = data['name']
        }


        if(!!data && !!data['avatar_contract_id'] && !!data['avatar_token_id']){
            let nftContract = data['avatar_contract_id']
            let token_id = data['avatar_token_id']
            let provider = null
            if(this.isMainnet()) {
                provider = new Provider([mainnetProviderUrl]);
            } else {
                provider = await new Provider([harbingerProviderUrl]);
            }


            const signer = Signer.fromSeed("my seed");
            signer.provider = provider;

            const Abi = {
                koilib_types: nftAbi.types,
                ...nftAbi
            }

            const contract = new Contract({
                id: nftContract,
                abi: Abi,
                provider,
                signer,
            });
            const nft = contract.functions;
            const result = (await nft.uri({
            })).result.value;
            let uri = normalizeIpfsUris(result)
            try {
                const metadata = await fetch(`${uri}/${token_id}`);
                const {image} = await metadata.json();
                avatar = normalizeIpfsUris(image);
            } catch(e){
                avatarDefault = true
            }
        } else {
            avatarDefault = true
        }

        await this.setState({
            links,
            bio,
            name,
            avatar,
            avatarDefault,
            loading: false
        })
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.testnet !== this.props.testnet || prevProps.owner !== this.props.owner){
            await this.setState({
                result: null,
                links: null,
                avatar: null,
                avatarDefault: false,
                bio: null,
                name: null,
                loading: true,
                noActiveKapAccount: false
            })
            this.componentDidMount()
        }

    }

    render() {
        const links = this.state.links
        const owner = this.props.owner
        const avatar = this.state.avatar
        const loading = this.state.loading
        const avatarDefault = this.state.avatarDefault
        const bio = this.state.bio
        const name = this.state.name
        const noActiveKapAccount = this.state.noActiveKapAccount
        return (

            <div className={"walletConnectedKapInfoChat noselect"}>
                {!!avatarDefault &&
                    <div className={"walletConnectedKapInfoProfile"}>
                        <Avatar seed={owner}/>
                    </div>
                }
                {!!avatar &&
                    <div className={"walletConnectedKapInfoProfile"}>
                        <img alt={"avatar"} className={"walletConnectedKapInfoProfilePic noselect"} src={avatar}/>
                    </div>
                }
                {!!name &&
                    <div className={"walletConnectedKapInfoDomain noselect"}>
                        <div className={"headerWalletConnectorModalContentKapTitleChat"}>
                            <img alt="kap Domains" className={"headerWalletConnectorModalContentWrapperKapLogo"} src={"/kap-logo.svg"}/>
                        </div> {name}
                    </div>
                }
                {!!bio &&
                    <div className={"walletConnectedKapInfoBio noselect"}>
                        {bio}
                    </div>
                }
                {!!links &&
                    <div className={"walletConnectedKapInfoIcons"}>
                        {!!links.btc &&
                        <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"https://blockstream.info/address/" + links.btc}>
                            <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faBitcoin}/>
                        </a>
                        }
                        {!!links.discord &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"https://discord.com/users/" + links.discord}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faDiscord}/>
                            </a>
                        }
                        {!!links.email &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"mailto:" + links.email}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faEnvelope}/>
                            </a>
                        }
                        {!!links.eth &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"https://etherscan.io/address/" + links.eth}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faEthereum}/>
                            </a>
                        }
                        {!!links.github &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"https://github.com/" + links.github}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faGithub}/>
                            </a>
                        }
                        {!!links.reddit &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"https://reddit.com/u/" + links.reddit}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faReddit}/>
                            </a>
                        }
                        {!!links.telegram &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"https://t.me/" + links.telegram}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faTelegram}/>
                            </a>
                        }
                        {!!links.twitter &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={"https://twitter.com/" + links.twitter}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faTwitter}/>
                            </a>
                        }
                        {!!links.website &&
                            <a target={"_blank"} className={"walletConnectedKapInfoIconsLink"} href={links.website}>
                                <FontAwesomeIcon className={"FontAwesomeIconOptions"} icon={faGlobe}/>
                            </a>
                        }
                    </div>
                }
                {loading &&
                    <div className={"walletConnectedKapLoading noselect"}>
                        <img alt={"loading"} src={"/profile-loader1.gif"}/>
                    </div>
                }
                {noActiveKapAccount &&
                    <div className={"walletConnectedNoActiveKapAccount noselect"}>
                        <p>no account data</p>
                    </div>
                }
            </div>
        )
    }
}

export default Landing;


