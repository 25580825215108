
export function getDecimalSeparator(input) {
    const [integerPart, decimalPart] = input.toString().split(".");
    const hasDot = input.toString().includes(".");
    if (!decimalPart && hasDot) {
        return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")  + ".";
    } else if (!decimalPart) {
        return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return `${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalPart}`;
}

export function getNumberForTransaction(number, decimals){
    let result = number
    decimals = Number(decimals)
    if(result.includes('.')){
        let numberSplit = result.split('.')
        let zeros = decimals - numberSplit[1].length
        if(zeros > 0) {
            numberSplit[1] = numberSplit[1].padEnd(decimals, '0')
        }
        result = numberSplit[0].concat(numberSplit[1])
    } else {
        result  = result.padEnd(result.length + decimals, '0')
    }
    return result
}



export function filterNumbers(input) {
    return  input.replace(/[^0-9]/g, "");
}

export function getNumber(input) {
    if(input === undefined){
        return 0
    }
    return  Number(filterNumbers(input));
}


export function removeSeperator(input) {
    if(!!input)
        return input.replaceAll(/,/g, '');
    return "";
}



export function getDecimalAmount(number, decimalCount) {
    // Convert the number to a string
    let numberString = number.toString();

    numberString = numberString.padEnd(numberString.length + decimalCount, '0');

    return numberString;

}


export function getLetterNumber(num, fixTo = 2) {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "k" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(fixTo).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  }

  export function getFormatAt(input, atNumber, fixTo){
    if (Number(input) >= atNumber) { return getLetterNumber(input, fixTo) }
    return getDecimalSeparator(input);

  }

export function getMarketTradesPriceFormat(value, letterAt = 10000){
    let result = value

    if(result >= letterAt) {
        result = getLetterNumber(result)
    } else if(result >= 1000) {
        result = Math.floor(result)
        result = getDecimalSeparator(result)
    } else if(result >= 100) {
        result = result.toFixed(2)
        //result = Number(result)
    } else if(result >= 10) {
        result = result.toFixed(3)
        result = Number(result)
    } else if(result == 0.00000000) {
        result = result.toFixed(2)
        //result = Number(result)
    }else if(result <= 0.00000001) {
        result = result.toFixed(8)
        //result = Number(result)
    }else if(result < 0.0000001) {
        result = result.toFixed(8)
        // result = Number(result)
    }else if(result < 0.000001) {
        result = result.toFixed(8)
        //  result = Number(result)
    }else if(result < 0.00001) {
        result = result.toFixed(7)
        // result = Number(result)
    }else if(result < 0.0001) {
        result = result.toFixed(6)
        //  result = Number(result)
    }else if(result < 0.001) {
        result = result.toFixed(6)
        //   result = Number(result)
    }else if(result < 0.01) {
        result = result.toFixed(5)
        // result = Number(result)
    }else if(result < 0.1) {
        result = result.toFixed(4)
        //  result = Number(result)
    }else if(result < 1) {
        result = result.toFixed(4)
        result = Number(result)
    }else if(result < 10) {
        result = result.toFixed(5)
        result = Number(result)
    } else {
        result = result.toFixed(4)
        result = Number(result)
    }

    return result
}


export function getOrdersLimitPriceFormat(value, letterAt = 10000){
    let result = value

    if(result >= letterAt) {
        result = getLetterNumber(result)
    } else if(result >= 1000000) {
        result = Math.floor(result)
        result = getDecimalSeparator(result)
    } else if(result >= 100000) {
        result = result.toFixed(1)
        //result = Number(result)
    } else if(result >= 1000) {
        result = result.toFixed(2)
        //result = Number(result)
    } else if(result >= 100) {
        result = result.toFixed(3)
        //result = Number(result)
    } else if(result >= 10) {
        result = result.toFixed(4)
        result = Number(result)
    } else if(result == 0.00000000) {
        result = result.toFixed(2)
        //result = Number(result)
    }else if(result <= 0.00000001) {
        result = result.toFixed(8)
        //result = Number(result)
    }else if(result < 0.0000001) {
        result = result.toFixed(8)
        // result = Number(result)
    }else if(result < 0.000001) {
        result = result.toFixed(8)
        //  result = Number(result)
    }else if(result < 0.00001) {
        result = result.toFixed(7)
        // result = Number(result)
    }else if(result < 0.0001) {
        result = result.toFixed(6)
        //  result = Number(result)
    }else if(result < 0.001) {
        result = result.toFixed(6)
        //   result = Number(result)
    }else if(result < 0.01) {
        result = result.toFixed(5)
        // result = Number(result)
    }else if(result < 0.1) {
        result = result.toFixed(4)
        //  result = Number(result)
    }else if(result < 1) {
        result = result.toFixed(4)
        result = Number(result)
    }else if(result < 10) {
        result = result.toFixed(5)
        result = Number(result)
    } else {
        result = result.toFixed(4)
        result = Number(result)
    }

    return result
}

export function getMarketTopBarPercentageFormat(value){
    let result = Number(value)

    if(result >= 100 || result <= -100) {
        result = Math.floor(result)
        result = getDecimalSeparator(result)
    } else{
        result = result.toFixed(2)
        result = Number(result)
    }

    return result

}
export function getMarketTopBarPriceFormat(value){
    let result = Number(value)

    if(result >= 10000000) {
        result = Math.floor(result)
        result = getDecimalSeparator(result)
    } else if(result >= 1000000) {
        result = result.toFixed(1)
        result = getDecimalSeparator(result)
    } else if(result >= 100000) {
        result = result.toFixed(2)
        result = getDecimalSeparator(result)
    } else if(result >= 10000) {
        result = result.toFixed(3)
        result = getDecimalSeparator(result)
    } else if(result >= 1000) {
        result = result.toFixed(4)
        result = getDecimalSeparator(result)
    } else if(result >= 100) {
        result = result.toFixed(5)
        result = Number(result)
    } else if(result >= 10) {
        result = result.toFixed(6)
        result = Number(result)
    } else if(result == 0) {
        result = result.toFixed(0)
    } else if(result < 0.1) {
        result = result.toFixed(8)
    } else if(result < 1) {
        result = result.toFixed(7)
    } else if(result < 10) {
        result = result.toFixed(8)
    } else {
        result = result.toFixed(5)
        result = Number(result)
    }

    return result
}


export function minFormat(str) {
    // Convert the string number to a float
    let num = str.replace(/,/g, '');
    if(num.includes('.')){
        let splitNum = num.split('.')
        let _integer = splitNum[0]
        let _decimals = splitNum[1]

        let _integerLength = _integer.length
        let _decimalsLength = _decimals.length
        if((_integerLength + _decimalsLength) > 8){
            let newDecimals = 8 - _integer.length
            if(_decimalsLength > newDecimals){
                if(Number(_integer) === 0) {
                    newDecimals++;
                    for (let i = newDecimals; i <= 8; i++) {
                        newDecimals = i
                        if (Number(_decimals.substring(0, newDecimals)) > 0) {
                            break;
                        }
                    }
                }
                _decimals =  _decimals.substring(0, newDecimals);
            }
        }
        num = _integer + "." + _decimals
        num = removeTrailingZeros(toFixedString(Number(num), 8))
    }

    return num
}

function removeTrailingZeros(str) {
    if (str.includes('.')) {
        str = str.replace(/0+$/, '');  // Remove trailing zeros
        if (str.endsWith('.')) {      // If the last character is a dot, remove it
            str = str.slice(0, -1);
        }
    }
    return str;
}

function toFixedString(num, decimals) {
    return parseFloat(num).toFixed(decimals);
}
 export function formatNumber(number, decimalCount, showDecimals=true) {
     if(!!number) {
         // Convert the number to a string
         let numberString = number.toString();

         // Prepend zeros if necessary to reach the desired decimal count
         if (decimalCount > numberString.length - 1) {
             const zerosToAdd = decimalCount - (numberString.length - 1);
             numberString = numberString.padStart(numberString.length + zerosToAdd, '0');
         }

         // Determine the position of the decimal point
         const decimalPosition = numberString.length - decimalCount;

         // Split the number into whole number and decimal parts
         const wholeNumberPart = numberString.slice(0, decimalPosition);
         const decimalPart = numberString.slice(decimalPosition);

         // Format the whole number part with commas
         const formattedWholeNumberPart = wholeNumberPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

         // Join the whole number part and decimal part with a decimal point
         let formattedNumber = (decimalCount > 0 && showDecimals) ? `${formattedWholeNumberPart}.${decimalPart}` : `${formattedWholeNumberPart}`;

         // Remove trailing zeros after the decimal point
         if (decimalCount > 0 && showDecimals) {
             formattedNumber = formattedNumber.replace(/\.?0+$/, '');
         }

         return formattedNumber;
     }
     return number;
 }

 export function getShortenTX(tx){
    return tx.slice()
 }

export function applyDecimals(number, decimals) {
    return Number(number) * Math.pow(10, -decimals);
}
