import { useState, useCallback } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import store from '../../../../redux/store/store';

import * as kondor from "kondor-js";
import { getUserData } from './useHelpFunctions';

async function connectKondor(setConnectedState) {
    let myAddress = '';
    try {

        const accounts = await kondor.getAccounts();
        if(accounts.length == 1) {
            const useAccount = accounts[0]
            getUserData(useAccount.address);
            let address = useAccount.address
            myAddress = address
            address =  address.slice(0,3) + "..." + address.slice(-3)
            setConnectedState({
                connectedKondor: true,
                connectedMKW: false,
                connectedWC: false,
                kondorAccounts: accounts,
                connectedAddress: useAccount.address,
                connectedName: useAccount.name,
                connectedAccount: useAccount,
                connectedAddressFormat: address,
                walletConnectAccounts: null,
            });

            const signer = await kondor.getSigner(useAccount.address);
            store.dispatch({
                type: "SIGNER",
                payload: { signer: signer }
            });
            store.dispatch({
                type: "KONDOR_ACCOUNTS",
                payload: { kondorAccounts: accounts },
            });
            store.dispatch({
                type: "WALLET_CONNECT_ACCOUNTS",
                payload: { walletConnectAccounts: [] },
            });
            await localStorage.setItem('connectedAddress', useAccount.address);
            await localStorage.setItem('connectedAddressFormat', address);
            await localStorage.setItem('kondorAccounts', JSON.stringify(accounts));
            await localStorage.setItem('walletConnectAccounts', null);
            await localStorage.setItem('connectedKondor', true);
            await localStorage.setItem('connectedMKW', false);
            await localStorage.setItem('connectedWC', false);
        } else if(accounts.length > 1) {
            await localStorage.setItem('kondorAccounts', JSON.stringify(accounts));

            setConnectedState({
                kondorWalletSelector: true,
                connectedMKW: false,
                connectedWC: false,
                kondorAccounts: accounts,
            });
        }
    } catch (e) {
        console.log(e);
    }
}

const useKondorConnectModal = () => {
    const [connectedState, setConnectedState] = useState({
        connectedKondor: false,
        connectedMKW: false,
        connectedWC: false,
        connectedAddress: '',
        connectedName: '',
        connectedAccount: '',
        connectedAddressFormat: '',
        kondorAccounts: null,
        walletConnectAccounts: null,
    });

    const connectKondorWallet = useCallback(async () => await connectKondor(setConnectedState), []);

    return { connectKondorWallet, connectedState };
};

export default useKondorConnectModal;
