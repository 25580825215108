import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {Col, Row} from 'react-bootstrap';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import {
    FormContainer,
    FormGroup,
} from '@/shared/components/form/FormElements';
import DatepickerField from "../../../UI/Datepickers/components/DatepickerField";
import {FormGroupField} from "../../../../shared/components/form/FormElements";

const DatePickers = ({ onSubmit }) => {
    const { t } = useTranslation('common');

    return (
        <Row>
            <Col xs={12} md={12} lg={12} xl={6}>
                <FormContainer>
                    <FormGroup>
                        <FormGroupField>
                            <DatepickerField
                                name="Sale Start"
                                dateFormat="dd/mm/yyyy h:mm aa"
                                showTimeInput
                            />
                        </FormGroupField>
                    </FormGroup>
                </FormContainer>
            </Col>
            <Col xs={12} md={12} lg={12} xl={6}>
                <FormContainer>
                    <FormGroup>
                        <FormContainer>
                            <DatepickerField
                                name="Sale End"
                                dateFormat="dd/mm/yyyy h:mm aa"
                                showTimeInput
                            />
                        </FormContainer>
                    </FormGroup>
                </FormContainer>
            </Col>
        </Row>
    );
};

DatePickers.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default DatePickers;
