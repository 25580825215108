import React, { Component } from "react";
import "./Chat.css";
import { crypto_emoji,
    smileys,
    people,
    animals,
    activities,
    food,
    symbols,
    travel,
    plant,
    objects,
    flags } from "./emojis"
import ReactGiphySearchbox from 'react-giphy-searchbox'
import {getRandomNumber} from "./random";
import axios from "axios";
import {DateTime} from "luxon";
import store from "../../../redux/store/store";
import KapInfoChat from "./KapInfoChat";
import NicknameInfoChat from "./NicknameInfoChat";
import styled from 'styled-components';
import {
    colorBorder,
    colorGreen,
    colorHover,
    colorText } from '../../../utils/palette';

const MAX_MESSAGE_TOOLTIP = 999
const MAX_MESSAGES = 1000
const INTERVAL_NEW_MESSAGE_MS = 1500

/*  <h1>{props.note}</h1> */

class ChatLarge extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            showPicker: false,
            showGifs: false,
            payloadValue: "",
            messages: [],
            isUserScrolling: false,
            messageCounter: 0,
            isReactivateAutoScrollProcessing: false, // added flag
            scrollNewMessagesAnimation: "scrollToBottom staybottom",
            showNewMessagesIndicator: false,
            lastId: 0,
            oldDateDay: 0,
            connectedId: null,
            usernameValue: localStorage.getItem('usernameValue'),
            testnet: false,
            store: store.getState(),
            showKapInfo: false,
            activeKapView: false,
            showNicknameInfo: false,
            activeNicknameView: false,
            userAddress: "",
            searchboxWidth: 0
         }
        this.unsubscribe = null;
        this.giphySearchboxRef = React.createRef();
        this.chatContainerRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
        this.addMessage = this.addMessage.bind(this);
        this.inputRef = React.createRef();
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    async useKapProfile(){
        await this.setState({
            activeKapView: false,
            activeNicknameView: false
        })
        setTimeout(() => {
            this.setState({
                activeKapView: true
            });
        }, 200);
    }

    async useNicknameProfile(){
        await this.setState({
            activeNicknameView: false,
            activeKapView: false
        })
        setTimeout(() => {
            this.setState({
                activeNicknameView: true
            });
        }, 200);
    }

    handleDocumentClick(event) {
        // Check if click occurred outside of trade selection filter component
        if(this.state.activeKapView){
            this.setState({
                activeKapView: false,
                showKapInfo: false
            })
        }
        if(this.state.activeNicknameView){
            this.setState({
                activeNicknameView: false,
                showNicknameInfo: false
            })
        }
    }

  setPayloadValue(payloadValue) {
    this.setState({payloadValue})
  }

  setShowPicker(showPicker){
    this.setState({showPicker})
  }
  setShowGifs(showGifs){
    this.setState({showGifs})
  }


  handleChange(event) {
    this.setPayloadValue(event.target.value)
  }

  setFocus(position) {
    const input_text = document.getElementById('input_payload');
    input_text.setSelectionRange(position, position);
    input_text.focus();
  }

  getPosition() {
    const input_text = document.getElementById('input_payload');
    return input_text.selectionStart;
  }

  async addEmoji(symbol) {
    const position = this.getPosition()
    const length = this.state.payloadValue.length
    if(length === position) {
        this.setPayloadValue(this.state.payloadValue + symbol);
    } else {
        this.setPayloadValue(this.state.payloadValue.substring(0, position) + symbol + this.state.payloadValue.substring(position));
    }
    await this.setFocus(position);
    this.inputRef.current.focus();
    this.inputRef.current.setSelectionRange(position + symbol.length, position + symbol.length);
  }

  getSymbols(data) {
    let result = []
    let data_emoji = (data)

    for(let i = 0; i < data_emoji.length; i++) {
        result.push(
            <span
                className="emojiSelector"
                key={i}
                onClick={() => this.addEmoji(data_emoji[i])}>
                {data_emoji[i]}
            </span>
        )
    }
    return result
  }
    async addMessage (message) {
        if (!this.chatContainerRef.current) {
            return;
        }
        if (this.state.isReactivateAutoScrollProcessing) {
            await new Promise((resolve) => setTimeout(resolve, 350));
            await this.addMessage(message);
        } else {
            const {messages} = this.state;
            let messageCounter = this.state.messageCounter += 1
            const newMessages = [...messages, message].slice(-MAX_MESSAGES);
            await this.setState({messages: newMessages});
            const {isUserScrolling} = this.state;
            if (!isUserScrolling) {
                messageCounter = 0
                const chatContainer = this.chatContainerRef.current;
                if (chatContainer) {
                    chatContainer.scrollTop = chatContainer.scrollHeight;
                }
            }

            await this.setState({messageCounter});
        }
    };

    handleScroll = () => {
        if (!this.state.isReactivateAutoScrollProcessing) {
            const {scrollHeight, scrollTop, clientHeight} = this.chatContainerRef.current;
            const isUserScrolling = scrollTop >= 0 && scrollTop + 7 < scrollHeight - clientHeight;
            if (isUserScrolling) {
                this.setState({
                    isUserScrolling,
                    scrollNewMessagesAnimation: "scrollToBottom animate-from-bottom-to-top",
                    showNewMessagesIndicator: true
                });
            }
            else{
                this.setState({
                    isUserScrolling,
                    scrollNewMessagesAnimation: "scrollToBottom animate-from-top-to-bottom"
                });
        }
        } else {
            this.setState({
                scrollNewMessagesAnimation: "scrollToBottom animate-from-top-to-bottom"
            });
        }
    };



    async reactivateAutoScroll(value){
        await this.setState({isReactivateAutoScrollProcessing: true})
        const chatContainer = this.chatContainerRef.current;
        const scrollTarget = chatContainer.scrollHeight - chatContainer.clientHeight;
        let scrollTop = chatContainer.scrollTop;
        const scrollStep = Math.ceil((scrollTarget - scrollTop) / 10);
        const animateScroll = () => {
            scrollTop += scrollStep;
            if (scrollTop > scrollTarget) {
                scrollTop = scrollTarget;
            }
            chatContainer.scrollTop = scrollTop;
            if (scrollTop < scrollTarget) {
                requestAnimationFrame(animateScroll);
            }
        };
        requestAnimationFrame(animateScroll);
        await this.setState({
            isUserScrolling: !value,
            messageCounter: 0,
        })

        await new Promise((resolve) => setTimeout(resolve, 250));
        await this.setState({
            isReactivateAutoScrollProcessing: false
        })
    }


    componentWillUnmount() {
        // Stop the interval and clean up
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        if (this.timer) {
            clearInterval(this.timer);
        }
        document.removeEventListener("click", this.handleDocumentClick);

        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }



    async handleMessage(){


        const connectedKondor = JSON.parse(localStorage.getItem('connectedKondor'));
        const connectedWC = JSON.parse(localStorage.getItem('connectedWC'));
        if(connectedKondor || connectedWC){
            let message = this.state.payloadValue
            if (message.length > 0) {
                try {

                    const connectedMKW = JSON.parse(localStorage.getItem('connectedMKW'));
                    const connectedKondor = JSON.parse(localStorage.getItem('connectedKondor'));

                    let userAddress = "unknown"
                    if(connectedMKW || connectedKondor) {
                        userAddress = localStorage.getItem('connectedAddress');
                    }
                    const chat_id = this.props.chatID;

                    const response = await axios.post(process.env.REACT_APP_DOMAIN + "/api/kct_message", {
                        "message": message,
                        "chat_id": chat_id,
                        "user": userAddress,
                        "signedMessage": "12345"
                    }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                    if(!!response.data?.error){
                        store.dispatch({
                            type: "ACCOUNT",
                            payload: {showAccount: "true"}
                        });
                    }
                    else {
                        await this.setState({
                            payloadValue: []
                        })
                    }
                    await this.setShowPicker(false)
                } catch (err) {
                    console.log(err)
                }
            }
        } else {
            store.dispatch({
                type: "WALLET",
                payload: {showConnectWallet: "true"}
            });
        }

    }


    async handleGif(gif){


        const connectedKondor = JSON.parse(localStorage.getItem('connectedKondor'));
        const connectedWC = JSON.parse(localStorage.getItem('connectedWC'));
        this.setShowGifs(false)
        if(connectedKondor || connectedWC){
            let message = gif?.images?.fixed_height?.url;
            if (message.length > 0) {
                try {

                    const connectedMKW = JSON.parse(localStorage.getItem('connectedMKW'));
                    const connectedKondor = JSON.parse(localStorage.getItem('connectedKondor'));

                    const gif_height = gif?.images?.fixed_height?.height;
                    const gif_width = gif?.images?.fixed_height?.width;

                    let userAddress = "unknown"
                    if(connectedMKW || connectedKondor) {
                        userAddress = localStorage.getItem('connectedAddress');
                    }
                    const chat_id = this.props.chatID;

                    const response = await axios.post(process.env.REACT_APP_DOMAIN + "/api/kct_gif", {
                        "message": message,
                        "gif_height": gif_height,
                        "gif_width": gif_width,
                        "chat_id": chat_id,
                        "user": userAddress,
                        "signedMessage": "12345"
                    }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                    if(!!response.data?.error){
                        store.dispatch({
                            type: "ACCOUNT",
                            payload: {showAccount: "true"}
                        });
                    }
                    else {
                        await this.setState({
                            payloadValue: []
                        })
                    }
                    await this.setShowPicker(false)
                } catch (err) {
                    console.log(err)
                }
            }
        } else {
            store.dispatch({
                type: "WALLET",
                payload: {showConnectWallet: "true"}
            });
        }

    }

    async componentDidUpdate(prevProps, prevState) {
        const { store } = this.state;
        const prevStore = prevState.store;

        if (store.id !== prevStore.id) {
            // if username changes, the chat needs to be build again
            await this.setState({
                lastId: 0,
                messages: [],
                messageCounter: 0,
                oldDateDay: 0,
                connectedId: 0
            })
            if (this.unsubscribe) {
                this.unsubscribe();
            }
            await this.componentDidMount()
        }
    }

    async fetchChatData() {
        try {
            const chat_id = this.props.chatID.toString();
            const response = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/kct_readAll`, {
                params: {
                    "chat_id": chat_id
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.data.success) {
                const data = response.data.rows;
                if (data.length > 0) {
                    this.prepareChat(data, false);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }


    async componentDidMount() {
        this.updateSearchboxWidth(); // Set the initial width

        this.unsubscribe = store.subscribe(() => {
            var _store = store.getState();
            this.setState({
                store: _store,
            });
        });
        // Scroll to the bottom of the chat window on initial render
        // Add event listeners for scrolling and wheeling
        const init_messages = false
        const simulate_messages = false
        const livechat = true
        const connectedId = localStorage.getItem('connectedId');

        document.addEventListener("click", this.handleDocumentClick);

        if(!!connectedId) {
            await this.setState({
                connectedId: connectedId
            })
        }

        if ( init_messages ) {
            for (let i = 0; i < 25; i++) {
                await this.addMessage(
                    <YourMessage key={"yourMessage"+i} className="message yourMessage">
                        <div className="head">
                            <span className="timestamp">28 Aug 22, 1:43 pm</span>
                            <span className="user">CryptoCraze</span>
                        </div>
                        <div className="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget consequat velit.
                            Curabitur
                            pretium, nulla a dictum vestibulum, mauris velit tempor ipsum, et malesuada nibh quam nec
                            nulla.
                            Aenean at aliquam lacus, a bibendum lacus. Nam sagittis neque id massa efficitur, in egestas
                            ipsum tempus. Cras at dapibus nisi, sit amet volutpat augue. Praesent iaculis luctus dolor,
                            at
                            dictum turpis volutpat et. Donec mollis ante arcu, ac efficitur nibh aliquet at. Vivamus
                            venenatis est a risus tristique scelerisque.
                        </div>
                    </YourMessage>
                );

                await this.addMessage(
                    <MyMessage key={"myMessage"+i} className="message myMessage">
                        <div className="head">
                            <span className="timestamp">28 Aug 22, 1:43 pm</span>
                        </div>
                        <div className="text">
                            m at accumsan sapien. Fusce tempor sapien in vehicula mollis. Vivamus elementum varius
                            ornare.
                            Etiam tincidunt efficitur eros ac posuere. Donec felis erat, bibendum
                        </div>
                    </MyMessage>
                )

                await this.addMessage(
                    <MyMessage key={"myMessage"+i} className="message myMessage">
                        <div className="head">
                            <span className="timestamp">28 Aug 22, 1:43 pm</span>
                        </div>
                        <div className="text">
                            <img src={'https://media4.giphy.com/media/vVzH2XY3Y0Ar6/200w.gif?cid=cd88db9dsby2dr0y0235m7yb4b4k9d3d5va6qtz5y8ioof9p&ep=v1_gifs_trending&rid=200w.gif&ct=g'}/>
                        </div>
                    </MyMessage>
                )
            }
        }


        if ( simulate_messages ) {
            let messageCount = 0;
            this.timer = setInterval(() => {
                const randomMessage = Math.random().toString(36).substring(2);
                if (getRandomNumber(0, 100, 0) % 2 === 1) {
                    this.addMessage(
                        <YourMessage key={"randomMessage_" + randomMessage} className="message yourMessage animate">
                            <div className="head">
                                <span className="timestamp">28 Aug 22, 1:43 pm</span>
                                <span className="user">CryptoCraze</span>
                            </div>
                            <div className="text">
                                {randomMessage} "_____": {messageCount}
                            </div>
                        </YourMessage>
                    );
                } else {
                    this.addMessage(
                        <MyMessage key={"randomMessage_" + randomMessage} className="message myMessage animate">
                            <div className="head">
                                <span className="timestamp">28 Aug 22, 1:43 pm</span>
                            </div>
                            <div className="text">
                                {randomMessage} "_____": {messageCount}
                            </div>
                        </MyMessage>
                    );
                }
                messageCount += 1;
                if (messageCount === 500) {
                    clearInterval(this.timer);
                }
            }, 1000);
        }

        if (livechat) {
            this.fetchChatData();

            // Set up the interval for the first time
            this.intervalId = setInterval(this.getMessageAfterLastId, INTERVAL_NEW_MESSAGE_MS);
        }
    }

    async showKapInfo(value, userAddress){
        await this.setState({
            showKapInfo: value,
            showNicknameInfo: !value,
            userAddress: userAddress

        })
        if(value){
            setTimeout(() => {
                this.setState({
                    activeKapView: true,
                    activeNicknameView: false
                });
            }, 200);
        }
    }

    async showNicknameInfo(value, userAddress){
        await this.setState({
            showNicknameInfo: value,
            showKapInfo: !value,
            userAddress: userAddress

        })
        if(value){
            setTimeout(() => {
                this.setState({
                    activeNicknameView: true,
                    activeKapView: false
                });
            }, 200);
        }
    }

    updateSearchboxWidth() {
        if (this.giphySearchboxRef && this.giphySearchboxRef.current) {
            const width = this.giphySearchboxRef.current.offsetWidth;
            this.setState({ searchboxWidth: width });
        }
    }

    getMessageAfterLastId = async () => {
        const { lastId } = this.state;
        try {
            const chat_id = this.props.chatID.toString();
            const response = await axios.get(process.env.REACT_APP_DOMAIN + "/api/kct_readAfterLastId", {
                params: {
                    "chat_id": chat_id,
                    "last_id": lastId
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });


            if (response.data.success) {
                const data = response.data.rows
                if (data.length > 0) {
                    this.prepareChat(data, true)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    async prepareChat(data, update){
        data.reverse();

        let oldDateDay = 0
        let dateDay = 0
        let lastId = this.state.lastId
        if(update) {
            oldDateDay = this.state.oldDateDay
        }
        for (let i = 0; i < data.length; i++){
            let entry = data[i]
            let timestamp = DateTime.fromSeconds(Number(entry.timestamp)).toFormat('h:mm a')
            let date = DateTime.fromSeconds(Number(entry.timestamp)).toFormat('d MMM yyyy')
            dateDay = Number(DateTime.fromSeconds(Number(data[i].timestamp)).toFormat('d'))
            let rnd = getRandomNumber(0, 1e9,1)

            if(dateDay !== oldDateDay && !update){
                await this.addMessage(
                    <div key={"yourMessageDate" + entry.id + rnd} className="message system">
                        <div className="head">
                            <span className="timestamp">{date}</span>
                        </div>
                    </div>
                )
            }
            oldDateDay = dateDay

            let user = entry.id_user
            let messageType = "yourMessage"
            let myMessage = false

            if(Number(user) === Number(this.state.connectedId)){
                messageType = "myMessage"
                myMessage = true
            }
            //let date = new Date(entry.timestamp.replace(' ', 'T'))
            if(messageType == "yourMessage") {
                await this.addMessage(
                    <YourMessage key={"yourMessage" + entry.id + rnd}
                         className={update ? "message " + messageType + " animate" : "message " + messageType + ""}>
                        <div className="head">
                            <span className="timestamp">{timestamp}</span>
                            {!myMessage && !entry.nickname_username &&
                                <span
                                    onClick={() => {
                                        this.setState({ activeKapView: false });
                                        this.showKapInfo(true, entry.address)
                                    }}
                                    className="user">{entry.name}</span>
                            }
                            {!myMessage && entry.nickname_username &&
                                <span
                                    onClick={() => {
                                        this.setState({ activeNicknameView: false });
                                        this.showNicknameInfo(true, entry.name)
                                    }}
                                    className="user">{entry.name}</span>
                            }
                        </div>
                        <div className="text">
                            {entry.gif && entry.gif_height != null && entry.gif_height > 0 &&
                                <img
                                    style={{
                                        height: `${entry.gif_height}px`,
                                        width: `${entry.gif_width}px`
                                    }}
                                    src={entry.message}
                                    alt="GIF"
                                />
                            }
                            {entry.gif && (entry.gif_height == null || entry.gif_height == 0) &&
                                <img
                                    style={{
                                        height: `200px`,
                                        width: 'auto'
                                    }}
                                    src={entry.message}
                                    alt="GIF"
                                />
                            }
                            {!entry.gif &&
                                <>{entry.message}</>
                            }
                        </div>
                    </YourMessage>
                );
            } else {
                await this.addMessage(
                    <MyMessage key={"myMessage" + entry.id + rnd}
                         className={update ? "message " + messageType + " animate" : "message " + messageType + ""}>
                        <div className="head">
                            <span className="timestamp">{timestamp}</span>
                            {!myMessage && !entry.nickname_username &&
                                <span
                                    onClick={() => {
                                        this.setState({ activeKapView: false });
                                        this.showKapInfo(true, entry.address)
                                    }}
                                    className="user">{entry.name}</span>
                            }
                            {!myMessage && entry.nickname_username &&
                                <span
                                    onClick={() => {
                                        this.setState({ activeNicknameView: false });
                                        this.showNicknameInfo(true, entry.name)
                                    }}
                                    className="user">{entry.name}</span>
                            }
                        </div>
                        <div className="text">
                            {entry.gif &&
                                <img src={entry.message}/>
                            }
                            {!entry.gif &&
                                <>{entry.message}</>
                            }
                        </div>
                    </MyMessage>
                );
            }
            lastId = entry.id
        }
        await this.setState({
            lastId: lastId,
            oldDateDay: oldDateDay
        })
    }


  render() {
        let showKapInfo = this.state.showKapInfo
        let showNicknameInfo = this.state.showNicknameInfo
        let userAddress = this.state.userAddress
        let testnet = this.state.testnet

        const { messages, showNewMessagesIndicator, messageCounter, scrollNewMessagesAnimation } = this.state;

      const { searchboxWidth } = this.state;
      const imageWidth = (searchboxWidth - 100) / 6;

        return (
            <div className="chatLargeWindow">
                {this.state.showPicker && (<div className="emojis">
                    <span className="group"><span role="img" aria-labelledby="">⚡</span> Favorites</span>
                    <span className="content">{this.getSymbols(crypto_emoji())}</span>
                    <span className="group"><span role="img" aria-labelledby="Smileys & Emotions">😀</span> Smiley & Emotion</span>
                    <span className="content">
    {this.getSymbols(smileys())}
    </span>
                    <span className="group"><span role="img"
                                                  aria-labelledby="People & Body">👋</span> People & Body</span>
                    <span className="content">
    {this.getSymbols(people())}
    </span>
                    <span className="group"><span role="img" aria-labelledby="Animals & Nature">🐵</span> Animals, Bird & Insect</span>
                    <span className="content">
    {this.getSymbols(animals())}
    </span>
                    <span className="group"><span role="img" aria-labelledby="Food & Drink">🍇</span> Food & Drink</span>
                    <span className="content">
    {this.getSymbols(food())}
    </span>
                    <span className="group"><span role="img"
                                                  aria-labelledby="Travel & Places">🌍</span> Travel & Place</span>
                    <span className="content">
    {this.getSymbols(travel())}
    </span>
                    <span className="group"><span role="img"
                                                  aria-labelledby="Activities">🎃</span> Sport & Activity</span>
                    <span className="content">
    {this.getSymbols(activities())}
    </span>
                    <span className="group"><span role="img" aria-labelledby="Objects">👓</span> Object</span>
                    <span className="content">
    {this.getSymbols(objects())}
    </span>
                    <span className="group"><span role="img" aria-labelledby="Plants">💐</span> Plant & Flower</span>
                    <span className="content">
    {this.getSymbols(plant())}
    </span>
                    <span className="group"><span role="img"
                                                  aria-labelledby="Symbols & Signs">🏧</span> Symbol & Sign</span>
                    <span className="content">
    {this.getSymbols(symbols())}
    </span>
                    <span className="group"><span role="img" aria-labelledby="Flags">🏁</span> Flag</span>
                    <span className="content">
    {this.getSymbols(flags())}
    </span>

                </div>)}


                <ChatLargeContent
                    ref={this.chatContainerRef}
                    onScroll={this.handleScroll}
                    className="chatLargeContent">
                    {messages}
                </ChatLargeContent>
                    <div ref={this.giphySearchboxRef} style={{ width: '100%' }}>
                        {this.state.showGifs && searchboxWidth > 100 &&
                        <ReactGiphySearchbox
                            gifListHeight={275}
                            rating={'r'}
                            wrapperClassName={"giphySearchbox"}
                            masonryConfig={[
                                {
                                    imageWidth: Math.floor(imageWidth),
                                    gutter: 10,
                                    columns: 6
                                } // Updated image width
                            ]}
                            apiKey="1w8sg9NhrKGW6U39Zty81oAKJap9fRMK"
                            onSelect={item => this.handleGif(item)}
                        />
                        }
                    </div>
                {showNewMessagesIndicator &&
                    <div className={"scrollToBottomContainer"}>
                        <div
                            className={scrollNewMessagesAnimation}>
                        <span
                            role={"img"} aria-label={"New messages"}
                            onClick={() => this.reactivateAutoScroll(true)}
                            className={"scrollToBottomIcon"}>📩</span>
                            {(messageCounter > 0) &&
                                <div
                                    onClick={() => this.reactivateAutoScroll(true)}
                                    className={"messageCounter noselect"}>
                                    {(messageCounter > MAX_MESSAGE_TOOLTIP) ? (MAX_MESSAGE_TOOLTIP + "+") : messageCounter}
                                </div>
                            }
                        </div>
                    </div>
                }

                <div className={"inputsWrapper"}>
                    <div className="inputs">
                        {!testnet &&
                            <span className="form">
                        <span className={this.state.showPicker ? 'smile active' : 'smile'}
                              onClick={() => {this.setShowGifs(false); this.setShowPicker(!this.state.showPicker)}}>
                        ☺
                        </span>
                        <span className={this.state.showGifs ? 'smile active' : 'smile'}
                              onClick={() => {this.setShowPicker(false); this.setShowGifs(!this.state.showGifs)}}>
                        📽
                        </span>
                        <span className="payload">
                            <input
                                ref={this.inputRef}
                                id="input_payload"
                                name="input_payload"
                                autoComplete={'off'}
                                placeholder={'Write your message'}
                                value={this.state.payloadValue}
                                onChange={(e) => this.handleChange(e)}
                                onKeyUp={(e) => (e.key === 'Enter' || e.keyCode === 13) ? this.handleMessage() : ''}
                                type="text" className="input"></input>
                        </span>
                        <span className="send">
                            <div
                                onClick={() => this.handleMessage()}
                                className={'submitMessage noselect'}>Send</div>
                        </span>
                    </span>
                        }
                        {testnet &&
                            <span className="form">
                            <span className="payload">
                                <input
                                    ref={this.inputRef}
                                    id="input_payload"
                                    name="input_payload"
                                    autoComplete={"off"}
                                    disabled={true}
                                    placeholder={"Write messages disabled"}
                                    type="text" className="input"></input>
                            </span>
                        </span>
                        }
                    </div>
                </div>
                {showKapInfo &&
                    <div onClick={() => this.useKapProfile()}>
                        <KapInfoChat
                            testnet={false}
                            owner={userAddress}
                        />
                    </div>
                }
                {showNicknameInfo &&
                    <div onClick={() => this.useNicknameProfile()}>
                        <NicknameInfoChat
                            testnet={false}
                            owner={userAddress}
                        />
                    </div>
                }
            </div>
        );
  }
}

export default ChatLarge;


const YourMessage = styled.div`
    color: ${colorText};

    .head .user {
        color: ${colorGreen};
    }
`;


const MyMessage = styled.div`
    color: ${colorText};

    .head .timestamp {
        color: ${colorGreen};
    }
`;


const ChatLargeContent = styled.div`
    border: 1px solid ${colorBorder};
    background-color: ${colorHover};
`;


