import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {Col, Row} from 'react-bootstrap';
import FormField from '@/shared/components/form/FormField';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import validate from './validate';
import styled from "styled-components";
import { colorAdditional, colorText } from '@/utils/palette';
import {
  row, paddingLeft, marginLeft, left, marginRight, paddingRight,
} from '@/utils/directions';

const ProjectSummaryWrap = styled.div`
  position: relative;

  hr {
    margin: 30px 0;
  }
`;

const ProjectSummaryCardTitleWrap = styled(CardTitleWrap)`
  display: flex;
  flex-direction: ${row};
  justify-content: space-between;
`;

const ProjectSummaryButton = styled(Button)`
  width: 100px;
  ${marginLeft}: 5px;
  margin-bottom: 0;
`;

const ProjectSummaryInfo = styled.table`
  margin-bottom: 5px;
  text-align: ${left};

  td {
    ${paddingLeft}: 10px;
    color: ${colorText};
  }

  th {
    color: ${colorAdditional};
    font-weight: 500;
  }

  td, th {
    padding-bottom: 5px;
  }
`;

const ProjectSummaryStats = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const ProjectBarWrap = styled.div`
  max-width: 460px;
  width: 100%;
  margin: auto 0;
`;

const ProjectSummaryStat = styled.div`
  ${marginRight}: auto;
  ${paddingRight}: 20px;

  p {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }

  span {
    color: ${colorAdditional};
    font-size: 13px;
  }
`;

const GridCard = styled(Card)`
  padding-bottom: 20px;

  p {
    margin-bottom: 0;
    padding: 20px 15px;
  }
`;

const GridCardBody = styled(CardBody)`
  padding: 0;
  text-align: left;
  height: 62px;
`;

const GridSmall = styled.p`
  
  @media screen and (min-width: 768px) and (max-width: 1920px) {
    display: none;
  }
`;

const VerticalForm = ({ onSubmit }) => {
  const { t } = useTranslation('common');
  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
      <Row>
        <Col md={12}>
          <h1 className="page-title">Submit Your Launchpad Form</h1>
          <h3 className="page-subhead subhead">
            This section summarizes briefly key information of your setup. Please review this section before
            submitting your launchpad form.
          </h3>
        </Col>
      </Row>
        <CardBody>
          <Form onSubmit={onSubmit} validate={validate}>
            {({
              handleSubmit,
              form: { reset },
            }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <CardTitleWrap>
                    <CardTitle>✅ Final Review and Submission</CardTitle>
                    <CardSubhead>
                      The launchpad can be accessed by everyone and unsold tokens will be refunded.
                    </CardSubhead>
                  </CardTitleWrap>
                  <Card>
                    <ProjectSummaryInfo>
                      <tbody>
                      <tr>
                        <th>Name:</th>
                        <td>Token</td>
                      </tr>
                      <tr>
                        <th>Symbol</th>
                        <td>TKN</td>
                      </tr>
                      <tr>
                        <th>Decimals:</th>
                        <td>8</td>
                      </tr>
                      </tbody>
                    </ProjectSummaryInfo>
                  </Card>
                  <FormGroup>
                    <FormGroupLabel>Description</FormGroupLabel>
                    <FormGroupField>
                      <Field
                          name="description"
                          component="textarea"
                          type="text"
                          disabled={true}
                          placeholder="Describe your token in 300 characters max."
                      />
                    </FormGroupField>
                  </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>

                                        <FormGroup>
                                            <FormGroupLabel>Start</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="September 18, 2024 1:00 AM"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>End</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="September 19, 2024 1:30 AM"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>

                                        <FormGroup>
                                            <FormGroupLabel>Softcap</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="500 KOIN"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>Hardcap</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="1,000 KOIN"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>

                                        <FormGroup>
                                            <FormGroupLabel>Minimum contribution</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="50 KOIN"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>Maximum contribution</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="500 KOIN"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>

                                        <FormGroup>
                                            <FormGroupLabel>Liquidity Lock</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="no lock"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>Koin funding for Liquidity Pool</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="80 %"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>

                                        <FormGroup>
                                            <FormGroupLabel>Launchpad Price</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="1 Koin = 80,000 Tokens"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>Start Trading Price</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="60,000 Tokens = 1 Koin"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>

                        <Row>
                            <Col md={4} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>Tokens for Sale</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="1,222,444"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                            <Col md={4} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>Token Fees</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="22,444"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                            <Col md={4} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>Tokens for Liquidity</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="4,522,444"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>

                                        <FormGroup>
                                            <FormGroupLabel>Required Tokens</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="45,564,566"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                            <Col md={6} sm={12}>
                                <GridCard>
                                    <GridCardBody>
                                        <FormGroup>
                                            <FormGroupLabel>Token Supply</FormGroupLabel>
                                            <FormGroupField>
                                                <Field
                                                    name="username"
                                                    component={FormField}
                                                    type="text"
                                                    placeholder="12,321,111"
                                                    disabled={true}
                                                />
                                            </FormGroupField>
                                        </FormGroup>
                                    </GridCardBody>
                                </GridCard>
                            </Col>
                        </Row>
                    </FormGroup>

                  <FormButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                  </FormButtonToolbar>
                </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};
http://localhost:3000/easydev/booking_dashboard
    VerticalForm.propTypes = {
      onSubmit: PropTypes.func.isRequired,
    };

export default VerticalForm;
