import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { ButtonToolbar } from '@/shared/components/Button';
import styled from 'styled-components';
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette';

import {
 flexFlow, left, paddingLeft, paddingRight, right,
} from '@/utils/directions';
import WalletIcon from "mdi-react/WalletIcon";
import {marginLeft, marginRight} from "../../../utils/directions";
import {
  colorAdditional,
  colorBackgroundBody,
  landingLightTextColor
} from "../../../utils/palette";
import useWalletConnectModal from '@/shared/components/wallet/hooks/useWalletConnectModal';
import useKondorConnectModal from '@/shared/components/wallet/hooks/useKondorModal';


const Modal = ({
 color, btn, title, message, colored, header,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  let Icon;

  const { connectWC } = useWalletConnectModal();
  const { connectKondorWallet} = useKondorConnectModal();

  const externalLinkIconKondor = <ModalLinkIcon className="lnr lnr-download"  href="https://chrome.google.com/webstore/detail/kondor/ghipkefkpgkladckmlmdnadmcchefhjl" target="_blank" rel="noopener noreferrer"/>;
  const externalLinkIconWalletConnect = <ModalLinkIcon className="lnr lnr-download"  href="https://walletconnect.com/" target="_blank" rel="noopener noreferrer"/>;
  const img_kondor = `${process.env.PUBLIC_URL}/img/wallets/kondor.png`;
  const img_wallet_connect = `${process.env.PUBLIC_URL}/img/wallets/walletconnect.png`;
  const img_koincity = `${process.env.PUBLIC_URL}/img/koincity-logo.png`;

  switch (color) {
    case 'primary':
      Icon = <ModalTitleIcon className="lnr lnr-apartment" />;
      break;
    case 'success':
      Icon = <ModalTitleIcon className="lnr lnr-thumbs-up" />;
      break;
    case 'warning':
      Icon = <ModalTitleIcon className="lnr lnr-flag" />;
      break;
    case 'danger':
      Icon = <ModalTitleIcon className="lnr lnr-cross-circle" />;
      break;
    default:
      break;
  }

  const buttonVariant = colored ? `outline-${color}` : color;

  return (
    <div>
      <TopbarWalletWrap onClick={toggle}>
      <TopbarWalletConnectButton type="button">
        <WalletIcon />
        <TopbarWalletConnect>
          {btn}
        </TopbarWalletConnect>
      </TopbarWalletConnectButton>
    </TopbarWalletWrap>
      <StyledModal
        show={modal}
        onHide={toggle}
        color={color}
        colored={colored}
        header={header}
      >
        <ModalHeader>
          <ModalCloseButton
              className="lnr lnr-cross"
              aria-label="close-btn"
              type="button"
              onClick={toggle}
          />
          {true &&
          <img style={{width: '42px', rotate: '10deg'}} src={img_koincity} alt=""/>
          }
          {false &&
          <>{header ? '' : Icon}</>
          }
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <StyledWalletTable>
            <tr>
              <td
                  onClick={() => {
                    connectKondorWallet(); // Call the connectKondorWallet function
                    toggle();  // Close the modal by toggling its state
                  }}
              >
                <img src={img_kondor} alt=""/>
              </td>
              <td
                  onClick={() => {
                    connectKondorWallet(); // Call the connectKondorWallet function
                    toggle();  // Close the modal by toggling its state
                  }}
              >Kondor</td>
              <td>
                <ExternalLink>
                  {externalLinkIconKondor}
                </ExternalLink>
              </td>
            </tr>
            <tr>
              <td
                  onClick={() => {
                    connectWC(); // Call the connectWC function
                    toggle();  // Close the modal by toggling its state
                  }}
              >
                <img src={img_wallet_connect} alt=""/>
              </td>
              <td
                  onClick={() => {
                    connectWC(); // Call the connectWC function
                    toggle();  // Close the modal by toggling its state
                  }}
              >
                WalletConnect
              </td>
              <td>
                <ExternalLink>
                  {externalLinkIconWalletConnect}
                </ExternalLink>
              </td>
            </tr>
          </StyledWalletTable>
          {message}
        </ModalBody>
      </StyledModal>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
};

export default Modal;

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }
  
  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;

const ModalLinkIcon = styled.a`
  width: 14px;
  height: 14px;
  font-size: 14px;
`;


const ExternalLink = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  background-color: transparent;
  text-decoration: none;

`;


const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const getColor = (color) => {
  switch (color) {
    case 'primary':
      return colorBlue;
    case 'success':
      return colorAccent;
    case 'warning':
      return colorYellow;
    case 'danger':
      return colorRed;

    default:
      return colorText;
  }
};

const StyledWalletTable = styled.table`
  margin-bottom: 5px;
  text-align: ${left};
  width: 100%;
  margin-top: 25px;
  
  tr {
    border-radius: 30px;
  }   
  
  tr:hover{
    background-color: ${colorBackgroundBody};
    border-radius: 30px;
    cursor: pointer;
  }

  td {
    ${paddingLeft}: 10px;
    color: ${colorText};
    line-height: 35px;
  }

  td:first-child{
    border-radius: 5px 0 0 20px;
  }

  td:first-child img{
    margin-left: 2px;
    height: 27px;
    width: auto;
  }
  
  td:nth-child(2){
    border-radius: 0;
  }
  td:nth-child(3){
    border-radius: 0 20px 20px 0;
    width: 47px;
  }

  th {
    color: ${colorAdditional};
    font-weight: 500;
  }

  td, th {
    padding-bottom: 5px;
  }
`;

const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: prop => !['colored', 'header'].includes(prop),
})`
  
  .modal-dialog {
    max-width: 385px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }
  
  ${ModalTitleIcon} {
    color: ${props => getColor(props.color)};
  }

  ${props => props.colored && `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${getColor(props.color)};
    }
  `}

  ${props => props.header && `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${getColor(props.color)};
    }
  `}
`;


const TopbarWalletWrap = styled.div`
  position: relative;
  margin-bottom: 0;
  height: 27px;
  border-radius: 3px;
  margin-top: 18px;
  ${marginLeft}: 0px;
  background-color: #f28705;

  @media screen and (max-width: 576px) {
    /*margin: inherit;*/
  }

  @media screen and (max-width: 320px) {
    margin: auto 0;
  }
`;



const TopbarWalletConnectButton = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  background-color: transparent;

  &:hover, &:active, &:focus:active {
    background-color: ${landingLightTextColor};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #232329;
  }
`;




const TopbarWalletConnect = styled.p`
  margin: auto 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  display: flex;
  ${marginLeft}: 5px;
  ${marginRight}: 5px;
  color: #232329;
  

  @media screen and (min-width: 480px) {
      display: block;
  }
`;


// endregion
