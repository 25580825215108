import React, { useState } from 'react';
import PropTypes, { number } from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ProgressBarRating from '@/shared/components/ProgressBarRating';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import { colorAdditional } from '@/utils/palette';
import {
 row, paddingLeft, marginLeft, left, marginRight, paddingRight,
} from '@/utils/directions';
import Statistics from './Statistics';
import ChatLarge from "../../../chat-large/Chat"
import {
  colorBackgroundBody,
  colorBackgroundCard, colorBubble,
  colorDarkText, colorFieldsBorder, colorScrollbar,
  colorText, colorTextAdditional,
  inboxButtonBackground, inboxButtonBackgroundHover,
  tokenNameShaddow,
  tokenNameShaddow2
} from '../../../../../utils/palette';
import { formatNumber } from '../../../../../shared/helpers/numberformats';
import RandomAnimatedBars from './RandomAnimatedBars';

const HeaderImage = styled.div`
    background-image: url(${(props) => props.imageUrl || 'default-image-url.jpg'});
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-top: 33.33%; /* Aspect ratio of 1280x427 (3:1) */
    border-radius: 12px 12px 0 0;
    z-index: 1;
    transition: transform 0.23s ease-in-out; /* Smooth zoom effect on hover */
`;

const Summary = ({ dir, launchpadData, evaluationData }) => {

    const [summaryScore, setSummaryScore] = useState(0);
    const [summaryMaxScore, setSummaryMaxScore] = useState(0);
    const [summaryPercentage, setSummaryPercentage] = useState(0);

    // Callback function to update score and maxScore from Statistics
    const handleUpdateSummaryScore = (score, maxScore) => {
        setSummaryScore(score);
        setSummaryMaxScore(maxScore);
        setSummaryPercentage(Math.floor(score/maxScore*100))
    };

    return (
      <Col md={12} lg={8} xl={8}>
        <Card>
          <CardBody>
            <ProjectSummaryWrap>
              <ProjectSummaryDescription>
                <p className="typography-message">
                  {launchpadData.description}
                </p>
              </ProjectSummaryDescription>
            </ProjectSummaryWrap>
          </CardBody>

          <RandomAnimatedBars key={launchpadData.sub_contract} sub_contract={launchpadData.sub_contract} raised={formatNumber(launchpadData.total_koin_amount, 8, true)}/>
          <CardBody>
            <ProjectSummaryWrap>

              <LaunchpadChat key={launchpadData.token_address}>
                <ChatLarge chatID={launchpadData.token_address} mainnet={true} hideMetadata={true}/>
              </LaunchpadChat>
            </ProjectSummaryWrap>
          </CardBody>

          <CardBody>
            <ProjectSummaryWrap>
              <Row>
                <Col md={12} lg={12} xl={6}>
                  <ProjectSummaryInfo>
                    <tbody>
                    <tr>
                      <th>Token name:</th>
                      <td>{launchpadData.name}</td>
                    </tr>
                    <tr>
                      <th>Token symbol:</th>
                      <td>{launchpadData.symbol}</td>
                    </tr>
                    <tr>
                      <th>Decimals:</th>
                      <td>{launchpadData.decimals}</td>
                    </tr>
                    <tr>
                      <th>Total supply:</th>
                      <td>{formatNumber(launchpadData.supply, launchpadData.decimals, false)}</td>
                    </tr>
                    </tbody>
                  </ProjectSummaryInfo>
                </Col>
                <Col md={12} lg={12} xl={6}>
                  <ProjectSummaryInfo>
                    <tbody>
                    <tr>
                      <th>Sale WAR per KOIN:</th>
                      <td>{launchpadData.tokenperkoinpresale}</td>
                    </tr>
                    <tr>
                      <th>Listing WAR per KOIN:</th>
                      <td>{launchpadData.tokenperkoinlisting}</td>
                    </tr>
                    <tr>
                      <th>Tokens for sale:</th>
                      <td>{formatNumber(launchpadData.sale_tokens, launchpadData.decimals, false)}</td>
                    </tr>
                    <tr>
                      <th>Tokens for listing:</th>
                      <td>{formatNumber(launchpadData.lp_tokens, launchpadData.decimals, false)}</td>
                    </tr>
                    </tbody>
                  </ProjectSummaryInfo>
                </Col>

              </Row>
    <Row>
                <Col md={12} lg={12} xl={6}>
                    <ProjectSummaryStats>
                          <ProjectSummaryStat>
                          <p>{evaluationData.audit == undefined ? '🏙️' : evaluationData.audit ? '✅' : '❌'} <span> Audit</span></p>
                        </ProjectSummaryStat>
                        {evaluationData.audit == undefined &&
                            <ProjectSummaryStat>
                              <p>🏙️ <span>LP-Lock</span></p>
                            </ProjectSummaryStat>
                        }
                        {evaluationData.audit != undefined &&
                          <ProjectSummaryStat>
                            {evaluationData.lp_burn &&
                                <p>✅ <span>LP-Burn</span></p>
                            }
                            {!evaluationData.lp_burn &&
                            <p>{evaluationData.lp_lock > 0 ? '✅' : '❌'} <span> LP-Lock</span></p>
                            }
                          </ProjectSummaryStat>
                        }
                        <ProjectSummaryStat>
                          <p>{evaluationData.audit == undefined ? '🏙️' : evaluationData.audit ? '✅' : '❌'}  <span> Fair</span></p>
                        </ProjectSummaryStat>
                    </ProjectSummaryStats>
                </Col>
                <Col  md={12} lg={12} xl={6}>
                  <ProjectSummaryStats>
                <ProjectBarWrap>
                  <ProgressBarRating now={summaryPercentage} top={true} label={`Ӄ-Rating: ${summaryScore}/${summaryMaxScore} (${summaryPercentage}%)`} color="blue" size="middle"/>
                </ProjectBarWrap></ProjectSummaryStats>
                </Col></Row>
              <hr/>
              {evaluationData.audit != undefined &&
                  <Statistics
                      key={launchpadData.token_address}
                      evaluationData={evaluationData}
                      launchpadData={launchpadData}
                      dir={dir}
                      updateSummaryScore={handleUpdateSummaryScore}
                  />
              }
            </ProjectSummaryWrap>
          </CardBody>
        </Card>

      </Col>

    );
};

Summary.propTypes = {
  dir: PropTypes.string.isRequired,
  launchpadData: PropTypes.object.isRequired,
  evaluationData: PropTypes.object.isRequired,
};

export default Summary;

// region STYLES

const ProjectSummaryWrap = styled.div`
  position: relative;

  hr {
    margin: 30px 0;
  }
`;
const LaunchpadChat = styled.div`
  width: calc(100% );
  border: 1px solid ${colorBackgroundCard};
  box-shadow: 1px 2px 20px ${colorBackgroundBody};
  border-radius: 4px;
  overflow: hidden;
  /*position: absolute;*/
  position: relative;
  height: 400px;
  text-align: left;
`;


const ProjectSummaryCardTitleWrap = styled(CardTitleWrap)`
  display: flex;
  flex-direction: ${row};
  justify-content: space-between;
`;

const ProjectSummaryButton = styled(Button)`
  width: 100px;
  ${marginLeft}: 5px;
  margin-bottom: 0;
`;

const ProjectSummaryInfo = styled.table`
  margin-bottom: 5px;
  text-align: ${left};
  min-width: 270px;

  td {
    ${paddingLeft}: 10px;
    color: ${colorText};
    width: 50%;
  }

  th {
    color: ${colorAdditional};
    font-weight: 500;
    /*text-align: right;*/
  }

  td, th {
    padding-bottom: 5px;
  }
`;
const ProjectSummaryDescription = styled.div`
  margin-bottom: 5px;
  word-wrap: revert;
  white-space: pre-wrap;
  text-align: ${left};
  p {
    line-height: 2em;    
    font-size: 1.1em;
  }

`;

const ProjectSummaryStats = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const ProjectBarWrap = styled.div`
  max-width: 460px;
  width: 100%;
  margin: auto 0;
  margin-top: 10px;
`;

const ProjectSummaryStat = styled.div`
  ${marginRight}: auto;
  ${paddingRight}: 20px;

  p {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }

  span {
    color: ${colorAdditional};
    font-size: 13px;
  }
`;


const BuySection = styled.div`
            position: sticky;
            top: 20px;
            `;

const FixedBuyCard = styled(Card)`
            background-color: #292929;
            color: #fff;
            border: 1px solid #444;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            `;

const SectionTitle = styled.h4`
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
            `;

const LaunchDetailsList = styled.ul`
            list-style-type: none;
            padding: 0;
            color: #fff;
            line-height: 1.8;

            li {
              margin-bottom: 10px;
          }
            `;

// endregion
