import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import MinusIcon from 'mdi-react/MinusIcon';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';
import { DatePickerWrapInterval } from './DatePickerElements';

const IntervalDatePickerField = ({ onChange }) => {
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
  });

  const handleChange = ({ startDate, endDate }) => {
    const { startDate: stateStartDate, endDate: stateEndDate } = state;

    const startDateCopy = startDate || stateStartDate;
    const endDateCopy = endDate || stateEndDate;

    setState({ startDate: startDateCopy, endDate: endDateCopy });
    onChange({ start: startDateCopy, end: endDateCopy });
  };

  const handleChangeStart = startDate => handleChange({ startDate });
  const handleChangeEnd = endDate => handleChange({ endDate });

  const { startDate, endDate } = state;
  return (
    <DatePickerWrapInterval>
      <DatePicker
          timeFormat="HH:mm"
          selected={startDate}
          selectsStart
          showTimeSelect
          startDate={startDate}
          endDate={endDate}
          onChange={handleChangeStart}
          dateFormat="MMMM d, yyyy h:mm aa"
          placeholderText="From"
          dropDownMode="select"
          withPortal={isMobileOnly}
      />
      <MinusIcon className="date-picker__svg" />
      <DatePicker
          timeFormat="HH:mm"
          selected={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          onChange={handleChangeEnd}
          placeholderText="To"
          dropDownMode="select"
          withPortal={isMobileOnly}
      />
    </DatePickerWrapInterval>
  );
};

IntervalDatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default renderComponentField(IntervalDatePickerField);
