import React, { Component } from 'react';
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';

class App extends Component {
    render() {
        const avatar = createAvatar(identicon, {
            size: 150,
            seed: this.props.seed
        }).toDataUriSync();

        return <img className={"walletConnectedKapInfoProfilePic noselect"} src={avatar} alt="Avatar" />;
    }
}

export default App;