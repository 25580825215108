export const testnetKapContract = "1H3k4zttAjF7qfTqfmKZ4ZCdUL3pRdGnpG";
export const mainnetKapContract = "13tmzDmfqCsbYT26C4CmKxq86d33senqH3";


export const kapInfoTestnetContract = "1E7jpWyqPuCLLQYdqYzZ4VatPXfMNhwizz";
export const kapInfoMainnetContract = "1EttfMuvTXGh8oE6vLiRF5JfqBvRiofFkB";

export const mainnetNicknamesContract = "1KD9Es7LBBjA1FY3ViCgQJ7e6WH1ipKbhz";


export function normalizeIpfsUris(uri: string) {
    let result = uri;
    if (uri.startsWith("ipfs://")) {
        const path = uri.indexOf("/", 7);
        if (path > -1) {
            result =
                "https://" +
                uri.substring(7, path) +
                ".ipfs.nftstorage.link" +
                uri.substring(path);
        } else {
            result = "https://" + uri.substring(7) + ".ipfs.nftstorage.link";
        }
    }
    return result;
}